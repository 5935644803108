import {EanAdresa, NazevNaseptavac, PrehledSpotrebOdberneMistoEdge, PrehledSpotrebSkupinyEdge, SkupinaNaseptavac} from '@eon.cz/gemini11-graphql';
import {Options} from 'highcharts/highcharts';
import {IntlShape} from 'react-intl';
import {ExportService} from '../../common/service/ExportService';
import {Interval} from './PrehledSpotrebyService';

const EXPORT_PREHLED_SPOTREBY_TABULKA_FILENAME = 'Přehled spotřeby.xlsx';

export type TabulkaFilterType = {
    interval?: Interval;
    omIds?: NazevNaseptavac[];
    eans?: EanAdresa[];
    nazevSkupinyOM?: SkupinaNaseptavac[];
};

export type VelicinyType = {
    spotrebaVelicina: boolean;
    cenaVelicina: boolean;
    registrVelicina: boolean;
    srovnani: boolean;
};

export const defaultFilterValues: Partial<TabulkaFilterType> = {
    eans: undefined,
    omIds: undefined,
    interval: Interval.THIS_YEAR,
    nazevSkupinyOM: undefined,
};

export const defaultVelicinyValues: VelicinyType = {
    spotrebaVelicina: true,
    cenaVelicina: true,
    registrVelicina: true,
    srovnani: true,
};

/**
 * It takes an array of objects, and exports it as an Excel file
 * @param {PrehledSpotrebOdberneMistoEdge[]} table - PrehledSpotrebOdberneMistoEdge[]
 * @param {IntlShape} intl - IntlShape - this is the intl object from react-intl. It's used to format
 * the numbers and dates.
 */
export const exportPrehledSpotrebyTable = async (table: PrehledSpotrebOdberneMistoEdge[], intl: IntlShape) => {
    // Sestavení jednotlivých řádků - nadpisy,prázdé řádky a řádky s hodnotami
    const titleTable = intl.formatMessage({id: 'prehled.spotreby.tabulka.nazev'});
    const descriptionTable = intl.formatMessage({id: 'prehled.spotreby.tabulka.podnapis'});
    const emptyRow = [''];
    const columnDataMonths = table[0]?.node?.spotreby?.mesicni?.map(({mesic, rok}) => `${mesic}/${rok}`) ?? [];
    const columnDataBase = [
        intl.formatMessage({id: 'prehled.spotreby.tabulka.ean'}),
        intl.formatMessage({id: 'prehled.spotreby.tabulka.nazev.om'}),
        intl.formatMessage({id: 'prehled.spotreby.tabulka.skupina.om'}),
        intl.formatMessage({id: 'prehled.spotreby.tabulka.adresa'}),
        '',
        intl.formatMessage({id: 'prehled.spotreby.tabulka.celkem'}),
        ...columnDataMonths,
    ];
    const columnDataSpotreba = ['', '', '', '', intl.formatMessage({id: 'prehled.spotreby.tabulka.spotreba'})];
    const columnDataRegistrVT = ['', '', '', '', intl.formatMessage({id: 'prehled.spotreby.tabulka.registr.nt'})];
    const columnDataRegistrNT = ['', '', '', '', intl.formatMessage({id: 'prehled.spotreby.tabulka.registr.vt'})];
    const columnDataCena = ['', '', '', '', intl.formatMessage({id: 'prehled.spotreby.tabulka.cena'})];

    const tabularData = table?.reduce((sum, {node}) => {
        const valueRorOm: Array<string | number> = [];
        const {
            ean,
            nastaveni: {nazev, skupinaNazev},
            adresa,
            spotreby: {mesicni, spotrebaCelkem},
        } = node;
        valueRorOm.push(ean ?? '', nazev ?? '', skupinaNazev ?? '', adresa ?? '');

        return [
            ...sum,
            valueRorOm,
            [...columnDataSpotreba, Number(spotrebaCelkem.spotreba.toFixed(3)), ...[...mesicni.map(({spotreba}) => Number(spotreba.toFixed(3)))]],
            [...columnDataRegistrVT, 0, ...[...mesicni.map(({stavRegistruVT}) => Number(stavRegistruVT.toFixed(3)))]],
            [...columnDataRegistrNT, 0, ...[...mesicni.map(({stavRegistruNT}) => Number(stavRegistruNT.toFixed(3)))]],
            [
                ...columnDataCena,
                intl.formatNumber(spotrebaCelkem.cena ?? 0, {currency: 'CZK', style: 'currency'}),
                ...[...mesicni.map(({cena}) => intl.formatNumber(cena ?? 0, {currency: 'CZK', style: 'currency'}))],
            ],
        ];
    }, [] as unknown[][]);

    const data = [emptyRow, columnDataBase, ...tabularData];
    const exportService = await import('../../common/service/ExportService').then(({ExportService}) => ExportService);
    exportService.exportArrayOfArraysAsXLSX(data, EXPORT_PREHLED_SPOTREBY_TABULKA_FILENAME, {title: titleTable, subTitle: descriptionTable});
};

/**
 * It takes an array of objects, and returns an array of arrays
 * @param {PrehledSpotrebSkupinyEdge[]} table - PrehledSpotrebSkupinyEdge[]
 * @param {IntlShape} intl - IntlShape - this is the intl object from react-intl
 */
export const exportPrehledSpotrebySkupinTable = (table: PrehledSpotrebSkupinyEdge[], intl: IntlShape) => {
    // Sestavení jednotlivých řádků - nadpisy,prázdé řádky a řádky s hodnotami
    const titleTable = intl.formatMessage({id: 'prehled.spotreby.tabulka.nazev'});
    const descriptionTable = intl.formatMessage({id: 'prehled.spotreby.tabulka.podnapis'});
    const emptyRow = [''];
    const columnDataMonths = table[0]?.node?.souhrn?.odbernaMista[0]?.spotreby?.mesicni?.map(({mesic, rok}) => `${mesic}/${rok}`) ?? [];
    const columnDataBase = [
        intl.formatMessage({id: 'prehled.spotreby.tabulka.nazev.om'}),
        intl.formatMessage({id: 'prehled.spotreby.tabulka.skupina.om'}),
        intl.formatMessage({id: 'prehled.spotreby.tabulka.ean'}),
        intl.formatMessage({id: 'prehled.spotreby.tabulka.adresa'}),
        '',
        intl.formatMessage({id: 'prehled.spotreby.tabulka.celkem'}),
        ...columnDataMonths,
    ];
    const columnDataSpotreba = ['', '', '', '', intl.formatMessage({id: 'prehled.spotreby.tabulka.spotreba'})];
    const columnDataRegistrVT = ['', '', '', '', intl.formatMessage({id: 'prehled.spotreby.tabulka.registr.nt'})];
    const columnDataRegistrNT = ['', '', '', '', intl.formatMessage({id: 'prehled.spotreby.tabulka.registr.vt'})];
    const columnDataCena = ['', '', '', '', intl.formatMessage({id: 'prehled.spotreby.tabulka.cena'})];

    const tabularData = table?.reduce((sum, {node}) => {
        const reduceData = node.souhrn.odbernaMista?.reduce((sumer, {adresa, ean, nastaveni: {nazev, skupinaNazev}, spotreby: {mesicni, spotrebaCelkem}}) => {
            const valueRorOm: Array<string | number> = [];

            valueRorOm.push(nazev ?? '', skupinaNazev ?? '', ean ?? '', adresa ?? '');
            return [
                ...sumer,
                valueRorOm,
                [...columnDataSpotreba, Number(spotrebaCelkem.spotreba.toFixed(3)), ...[...mesicni.map(({spotreba}) => Number(spotreba.toFixed(3)))]],
                [...columnDataRegistrVT, 0, ...[...mesicni.map(({stavRegistruVT}) => Number(stavRegistruVT.toFixed(3)))]],
                [...columnDataRegistrNT, 0, ...[...mesicni.map(({stavRegistruNT}) => Number(stavRegistruNT.toFixed(3)))]],
                [
                    ...columnDataCena,
                    intl.formatNumber(spotrebaCelkem.cena ?? 0, {currency: 'CZK', style: 'currency'}),
                    ...[...mesicni.map(({cena}) => intl.formatNumber(cena ?? 0, {currency: 'CZK', style: 'currency'}))],
                ],
            ];
        }, [] as unknown[][]);

        return [...sum, ...reduceData];
    }, [] as unknown[][]);

    const data = [emptyRow, columnDataBase, ...tabularData];

    ExportService.exportArrayOfArraysAsXLSX(data, EXPORT_PREHLED_SPOTREBY_TABULKA_FILENAME, {title: titleTable, subTitle: descriptionTable});
};

export const options: Options = {
    title: {text: '', style: {display: 'none'}},
    chart: {
        width: 120,
        height: 100,
    },
    credits: {enabled: false},
    yAxis: {
        title: {
            text: '',
        },
        labels: {
            enabled: false,
        },
    },
    exporting: {
        scale: 1,
        buttons: {
            contextButton: {
                enabled: false,
            },
        },
    },
    legend: {
        enabled: false,
    },
    plotOptions: {
        series: {
            marker: {
                enabled: false,
            },
            states: {
                hover: {
                    enabled: false,
                },
            },
        },
    },
    xAxis: {
        title: {
            text: '',
        },
        labels: {
            enabled: false,
        },
        grid: {
            enabled: true,
            borderWidth: 0,
        },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        tickColor: 'transparent',
        lineWidth: 0,
    },
    tooltip: {
        enabled: false,
    },
};

export const hodnotaColor = (hodnota: number) => {
    if (Math.sign(hodnota) === 1) return 'error';
    if (Math.sign(hodnota) === -1) return 'green';
    return 'GrayText';
};

import {ApolloProvider} from '@apollo/client';
import {CacheProvider, EmotionCache} from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import App, {AppContext, AppProps} from 'next/app';
import Head from 'next/head';
import Router, {useRouter} from 'next/router';
import {useEffect, useMemo} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {CustomFormats, RawIntlProvider, createIntl, createIntlCache} from 'react-intl';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import persistStore from 'redux-persist/lib/persistStore';
import {isLogged} from '../client/auth/service/LoginService';
import {PageRoute} from '../client/common/PageRouteModel';
import {LoadingDialog} from '../client/common/components/dialogs/LoadingDialog';
import {ErrorFallback} from '../client/common/components/error/ErrorFallback';
import {PageLayout} from '../client/common/components/layout/PageLayout';
import {browserSupoort} from '../client/common/constants';
import {useApollo} from '../client/lib/apolloClient';
import createEmotionCache from '../client/lib/createEmotionCache';
import store from '../client/lib/store';
import {useTheme} from '../client/lib/theme';
import Czech from '../lang/cs.json';
import English from '../lang/en.json';
/* Root stylesheet */
import cs from 'date-fns/locale/cs';
import '../public/static/fonts/BrixSans/stylesheet.css';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// This is optional but highly recommended
// since it prevents memory leak
const intlCache = createIntlCache();

Router.events.on('routeChangeStart', () => <LoadingDialog open />);

type Props = AppProps & {
    readonly statusCode: number;
    readonly emotionCache?: EmotionCache;
    readonly locale: string;
    readonly formats: CustomFormats | undefined;
};
const persistor = persistStore(store);
const MyApp = (props: Props) => {
    const {Component, locale, formats, pageProps, statusCode, emotionCache = clientSideEmotionCache} = props;

    const apolloClient = useApollo(pageProps, store.dispatch);

    const {theme} = useTheme();

    const {push, pathname} = useRouter();

    const [shortLocale] = locale ? locale.split('-') : ['en'];

    const messages = useMemo(() => {
        switch (shortLocale) {
            case 'cs':
                return Czech;
                break;
            case 'en':
                return English;
                break;
            default:
                return Czech;
        }
    }, [shortLocale]);

    useEffect(() => {
        if (!browserSupoort(window.navigator.userAgent)) {
            push('/warning.html');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isLogged() && pathname !== PageRoute.ROOT && pathname !== PageRoute.LOGIN && pathname !== '/oauth/callback') {
            push(PageRoute.LOGIN);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ApolloProvider client={apolloClient}>
                    <RawIntlProvider value={createIntl({locale, messages: messages as Record<string, any>, formats}, intlCache)}>
                        <CacheProvider value={emotionCache}>
                            <ThemeProvider theme={theme}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={cs}>
                                    <CssBaseline />
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <Head>
                                            <meta
                                                name="viewport"
                                                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                                            />
                                            {/* eslint-disable-next-line i18next/no-literal-string */}
                                            <title>Spotřeba pod palcem</title>
                                        </Head>
                                        {/* <GoogleTagManager gtmId={GA_TRACKING_ID} /> */}
                                        <PageLayout statusCode={statusCode}>
                                            <Component {...pageProps} statusCode={statusCode} />
                                        </PageLayout>
                                    </ErrorBoundary>
                                </LocalizationProvider>
                            </ThemeProvider>
                        </CacheProvider>
                    </RawIntlProvider>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    );
};

const getInitialProps = async (appContext: Partial<AppContext>) => {
    const {ctx} = appContext;

    const [appProps] = await Promise.all([App.getInitialProps(appContext as AppContext)]);

    const statusCode = ctx?.res?.statusCode;

    const formats = getFormats();

    return {
        ...appProps,
        statusCode,
        formats,
        locale: getLocales(ctx?.req?.headers['accept-language']),
    };
};

MyApp.getInitialProps = getInitialProps;

const getLocales = (str: string | undefined) => {
    return str?.split(',').map((type) => type.split(';')[0]?.trim().replace('*', ''))[0] ?? 'cs';
};

const getFormats = () => ({
    number: {
        CS: {
            style: 'currency',
            currency: 'CZK',
        },
        EN: {
            style: 'currency',
            currency: 'US',
        },
    },
});

export default MyApp;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {Store} from '../../lib/StoreType';
import {AnomalieFilterType, defaultAnomalieFilterValues, defaultGrafFilterValues, GrafFilterType} from '../service/PrehledSpotrebyService';
import {defaultFilterValues, TabulkaFilterType} from '../service/PrehledSpotrebyTabulkaService';

export type PrehledSpotrebyStore = {
    readonly tabValue: number;
    readonly tabulkaFilter: {
        readonly filter: Partial<TabulkaFilterType>;
        readonly run: boolean;
    };
    readonly grafFilter: {
        readonly filter: GrafFilterType;
        readonly run: boolean;
    };
    readonly anomalieFilter: {
        readonly filter: Partial<AnomalieFilterType>;
        readonly run: boolean;
    };
};

const initialState: PrehledSpotrebyStore = {
    tabValue: 0,
    tabulkaFilter: {
        filter: defaultFilterValues,
        run: false,
    },
    grafFilter: {
        filter: defaultGrafFilterValues,
        run: false,
    },
    anomalieFilter: {
        filter: defaultAnomalieFilterValues,
        run: false,
    },
};

export const prehledSpotrebySlice = createSlice({
    name: 'prehledSpotreby',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setTabValue: (state: PrehledSpotrebyStore, {payload}: PayloadAction<number>): PrehledSpotrebyStore => ({
            ...state,
            tabValue: payload,
        }),

        setTabulkaFilter: (
            state: PrehledSpotrebyStore,
            {payload}: PayloadAction<{filter: Partial<TabulkaFilterType>; run: boolean}>,
        ): PrehledSpotrebyStore => ({
            ...state,
            tabulkaFilter: {
                filter: payload.filter,
                run: payload.run,
            },
        }),
        setGrafFilter: (state: PrehledSpotrebyStore, {payload}: PayloadAction<{filter: GrafFilterType; run: boolean}>): PrehledSpotrebyStore => ({
            ...state,
            grafFilter: {
                filter: payload.filter,
                run: payload.run,
            },
        }),
        setAnomalieFilter: (
            state: PrehledSpotrebyStore,
            {payload}: PayloadAction<{filter: Partial<AnomalieFilterType>; run: boolean}>,
        ): PrehledSpotrebyStore => ({
            ...state,
            anomalieFilter: {
                filter: payload.filter,
                run: payload.run,
            },
        }),
        setDefaultFilters: (state: PrehledSpotrebyStore): PrehledSpotrebyStore => ({
            ...state,
            tabulkaFilter: {filter: defaultFilterValues, run: false},
            grafFilter: {filter: defaultGrafFilterValues, run: false},
            anomalieFilter: {filter: defaultAnomalieFilterValues, run: false},
        }),
    },
});

export const {setTabulkaFilter, setGrafFilter, setAnomalieFilter, setTabValue} = prehledSpotrebySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const common = (state: Store) => state.common;

export default prehledSpotrebySlice.reducer;

import {Context, Produkt} from '@eon.cz/gemini11-graphql';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {ValidationError} from '../utils/CommonTypes';

export type CommonStore = {
    readonly errorLoginConfig?: boolean | undefined;
    readonly loginConfigDisabled?: boolean | undefined;
    readonly errorLogin?: ValidationError | undefined;
    readonly loginSuccess?: boolean;
    readonly isFetchingLogin?: boolean;
    readonly logouting?: boolean;
    readonly isFetchingLoginConfig?: boolean;
    readonly stopChangeDefaultPath?: boolean;
    readonly produkt?: Produkt;
    readonly me?: Context;
    readonly selfcareLogin?: boolean;
};

const initialState: CommonStore = {
    errorLoginConfig: undefined,
    errorLogin: undefined,
    loginSuccess: false,
    isFetchingLoginConfig: false,
    logouting: false,
    loginConfigDisabled: undefined,
    isFetchingLogin: false,
    stopChangeDefaultPath: false,
    produkt: undefined,
    me: undefined,
    selfcareLogin: false,
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        fetchingLogin: (state: CommonStore): CommonStore => ({...state, isFetchingLogin: true}),
        setSelfcareLogin: (state: CommonStore, {payload}: PayloadAction<boolean>): CommonStore => ({...state, selfcareLogin: payload}),
        setProdukt: (state: CommonStore, {payload}: PayloadAction<Produkt>): CommonStore => ({...state, produkt: payload}),
        setMe: (state: CommonStore, {payload}: PayloadAction<Context>): CommonStore => ({...state, me: payload}),
        fetchedMe: (state: CommonStore): CommonStore => ({...state, stopChangeDefaultPath: true}),

        fetchedLogin: (state: CommonStore): CommonStore => ({
            ...state,
            isFetchingLogin: false,
            errorLogin: undefined,
            loginSuccess: true,
        }),

        errorfetchedLogin: (state: CommonStore, {payload}: PayloadAction<ValidationError>): CommonStore => ({
            ...state,
            isFetchingLogin: false,
            errorLogin: payload,
        }),
        errorfetchedLoginConfig: (state: CommonStore, {payload}: PayloadAction<ValidationError>): CommonStore => ({
            ...state,
            isFetchingLoginConfig: false,
            errorLogin: payload,
        }),

        logouting: (state: CommonStore, {payload}: PayloadAction<boolean>): CommonStore => ({
            ...state,
            logouting: payload,
            loginSuccess: false,
            stopChangeDefaultPath: false,
        }),

        loginConfigFetching: (state: CommonStore): CommonStore => {
            return {...state, isFetchingLoginConfig: true};
        },

        loginConfigFetched: (state: CommonStore, {payload}: PayloadAction<boolean>): CommonStore => {
            return {...state, isFetchingLoginConfig: false, loginConfigDisabled: payload};
        },
    },
});

export const {
    errorfetchedLogin,
    fetchedLogin,
    fetchingLogin,
    loginConfigFetched,
    loginConfigFetching,
    logouting,
    fetchedMe,
    setProdukt,
    setMe,
    errorfetchedLoginConfig,
    setSelfcareLogin,
} = commonSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const common = (state: any) => state.common;

export default commonSlice.reducer;

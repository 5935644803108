import {ApolloClient} from '@apollo/client';
import {Context, Produkt} from '@eon.cz/gemini11-graphql';
import axios from 'axios';
import Cookie from 'js-cookie';
import Router from 'next/router';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';
import {BackendEndpoints} from '../../../server/BackendEndpoints';
import {ErrorResponse, getErrorMessage, removeCookie} from '../../auth/service/LoginService';
import {RootActionType} from '../../lib/rootAction';
import {NotificationType} from '../../models/notification/NotificationModel';
import {PageRoute} from '../PageRouteModel';
import {addNotification} from '../components/notifications/reducer/NotificationSlice';
import {logged} from '../constants';
import {
    errorfetchedLogin,
    errorfetchedLoginConfig,
    fetchedLogin,
    fetchedMe,
    fetchingLogin,
    loginConfigFetched,
    loginConfigFetching,
    logouting,
    setMe,
    setProdukt,
    setSelfcareLogin,
} from '../reducers/CommonSlice';
import {ValidationErrorType} from '../utils/CommonTypes';
import {redirectURI} from '../utils/EnvironmentUtils';

type AuthenticateResponse = {
    readonly data: {
        readonly expiresIn: number;
        readonly token: string;
    };
};

type LoginConfigResponse = {
    readonly data: {
        readonly loginDisabled: boolean;
    };
};

type CommonActionType = {
    logout: (client: ApolloClient<any>, error?: ErrorResponse | undefined) => void;
    backToD24: (client: ApolloClient<any>) => void;
    reset: () => void;
    fetchMe: () => void;
    resetStore: () => void;
    fetchLogin: (credentials: {readonly code: string | null; readonly typ?: 'OAUTH' | 'LOGIN'}) => void;
    fetchLoginConfig: () => void;
    setProdukt: (produkt: Produkt) => void;
    setMe: (me: Context) => void;
    setSelcare: (selfcare: boolean) => void;
};

/**
 * Set auth token to cookie
 *
 * @param token Token
 * @param expiresIn Expiration time in seconds, undefined for session cookie
 */
export const setCookie = (expiresIn?: number): void => {
    window.sessionStorage.setItem('logged_spp', 'true');
    Cookie.set(logged, Math.floor(Math.random() * 1_000_000_000).toString(), {
        expires: expiresIn ? expiresIn / 86_400 : undefined,
        sameSite: 'strict',
    });
};

/**
 * It sets a cookie and redirects to the root page.
 * @param {string} token - The token that you get from the server.
 * @param {number} expiresIn - number - The number of seconds until the cookie expires.
 */
const doLogin = async (expiresIn: number) => {
    setCookie(expiresIn);
    await Router.push({pathname: PageRoute.ROOT});
};

/**
 * It takes an error string and returns a message string
 * @param error - The error code returned by the server.
 * @returns the value of the key in the object.
 */
const setErrorMessage = (error: 'INVALID_CLIENT') => {
    const errorMessage = {
        ['INVALID_CLIENT']: 'Špatný client ID.',
    };
    return errorMessage[error] ?? 'Neočekávaná chyba.';
};

/**
 * It returns an object with functions that dispatch actions to the Redux store
 */
export const useCommonAction = (): CommonActionType => {
    const dispatch = useDispatch();

    return {
        logout: (client: ApolloClient<any>, error) => {
            dispatch(logouting(true));
            const routeParams = error ? {pathname: PageRoute.LOGIN, query: {error}} : {pathname: PageRoute.LOGIN};
            // waiting for reset apollo
            if (typeof window !== 'undefined') {
                axios.post(`${window?.location?.origin}/logout`).then(() =>
                    client.cache
                        .reset()
                        .then(() => {
                            removeCookie();
                            Router.push(routeParams)
                                .then(() => {
                                    // Reset store
                                    dispatch({type: RootActionType.LOGOUT});
                                    dispatch(logouting(false));
                                    const errorMessage = getErrorMessage(error as ErrorResponse);
                                    if (error)
                                        dispatch(
                                            addNotification({
                                                type: NotificationType.ERROR,
                                                text: <FormattedMessage id={errorMessage} />,
                                            }),
                                        );
                                })
                                .catch((error) => new Error(error))
                                .finally(() => dispatch(logouting(false)));
                        })
                        .catch((error) => new Error(error)),
                );
            }
        },
        backToD24: (client: ApolloClient<any>) => {
            axios.post(`${window?.location?.origin}/logout`).then(() =>
                client.cache
                    .reset()
                    .then(() => {
                        removeCookie();
                        Router.push(`${redirectURI}`);
                    })
                    .catch((error) => new Error(error)),
            );
        },
        reset: () => {
            // Reset store

            Router.push({pathname: PageRoute.ROOT}).then(() => {
                // Reset store

                dispatch(logouting(false));
            });
        },
        fetchMe: () => {
            dispatch(fetchedMe());
        },
        setSelcare: (selfcare) => {
            dispatch(setSelfcareLogin(selfcare));
        },
        setProdukt: (produkt: Produkt) => {
            if (produkt) dispatch(setProdukt(produkt));
        },
        setMe: (me: Context) => {
            if (me) dispatch(setMe(me));
        },
        resetStore: () => {
            // Reset store
            window.localStorage.clear();

            // Reset store

            dispatch(logouting(false));
        },
        fetchLogin: ({code, typ}: {readonly code: string | null; readonly typ?: 'OAUTH' | 'LOGIN'}) => {
            dispatch(fetchingLogin());

            axios
                .post<unknown, AuthenticateResponse>(
                    `${window.location.origin}/authenticate`,
                    {
                        typ: typ || 'OAUTH',
                        code,
                    },
                    {
                        validateStatus: (status) => status >= 200 && status <= 302,
                    },
                )
                .then((res) => {
                    dispatch(fetchedLogin());
                    doLogin(res.data.expiresIn);
                })
                .catch((err) => {
                    if ([401, 403].includes(err.response?.status)) {
                        dispatch(
                            errorfetchedLogin({
                                message: setErrorMessage(err.response?.data?.error),
                                code: err.response.data.error ?? 'CUSTOM',
                                type: ValidationErrorType.WARNING,
                            }),
                        );
                    }
                    if (err.response?.status === 500) {
                        dispatch(
                            errorfetchedLogin({
                                code: 'CUSTOM',
                                message: 'Chyba na straně serveru.',
                                type: ValidationErrorType.CRITICAL,
                            }),
                        );
                    }
                });
        },
        fetchLoginConfig: () => {
            dispatch(loginConfigFetching());
            axios
                .get<unknown, LoginConfigResponse>(`${window.location.origin}/api/${BackendEndpoints.LOGIN_CONFIG}`)
                .then((res) => {
                    dispatch(loginConfigFetched(res.data.loginDisabled));
                })
                .catch(() => {
                    dispatch(
                        errorfetchedLoginConfig({
                            code: 'CUSTOM',
                            message: 'Chyba na straně serveru.',
                            type: ValidationErrorType.CRITICAL,
                        }),
                    );
                });
        },
    };
};

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type SortClickedAction = {
    readonly gridName: string;
    readonly column: string;
    readonly firstOrderByAsc?: boolean;
};

type SetPageSizeAction = {
    readonly gridName: string;
    readonly pageSize: number;
};

type GoPrevPageAction = {
    readonly gridName: string;
    readonly before: string;
};

type GoNextPageAction = {
    readonly gridName: string;
    readonly after: string;
};

type GoFirstPageAction = {
    readonly gridName: string;
};

type SetInitialDataAction = {
    readonly gridName: string;
    readonly initialData: PagingSortingGridStoreData;
};

type ClearDataAction = {
    readonly gridName: string;
};

/**
 * Model holding paging info
 */
export type GridPagingModel = {
    /**
     * How many entries to show from beginning
     */
    readonly first: number | undefined;

    /**
     * How many entries to show from end
     */
    readonly last: number | undefined;

    /**
     * Cursor for before
     */
    readonly before: string | undefined;

    /**
     * Cursor for after
     */
    readonly after: string | undefined;
};

/**
 * Model for storing sorting info
 */
export type GridOrderModel = {
    /**
     * Column by which to order
     */
    column: string;

    /**
     * Order is ascending
     */
    asc: boolean | undefined;
};

export type PagingSortingGridStoreData = {
    paging: GridPagingModel;
    order: GridOrderModel | undefined;
    page: number | undefined;
};

/**
 * Store for paging and sorting information for all tables
 */
export type PagingSortingGridState = {
    [key: string]: PagingSortingGridStoreData;
};

export const paginationSlice = createSlice({
    name: 'pagination',
    initialState: {} as PagingSortingGridState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        columnSortingClicked: (state: PagingSortingGridState, {payload}: PayloadAction<SortClickedAction>) => {
            const gridName = payload ? payload.gridName : '';
            const column = payload ? payload.column : '';
            const firstOrderByAsc = payload ? payload.firstOrderByAsc : false;

            if (!state[gridName]) {
                // Grid info does not exist - do nothing
                // eslint-disable-next-line no-console
                console.warn(`COLUMN_SORT_CLICKED: Grid data for '${gridName}' not found.`);
                return state;
            }

            const newOrder: GridOrderModel = {
                column: column as string,
                asc: state[gridName]?.order?.column === column ? !state[gridName]?.order?.asc : !!firstOrderByAsc,
            };

            const newPaging: GridPagingModel = {
                before: undefined,
                after: undefined,
                first: state[gridName]?.paging.first !== undefined ? state[gridName]?.paging.first : state[gridName]?.paging.last,
                last: undefined,
            };

            return {
                ...state,
                [gridName]: {
                    order: newOrder,
                    paging: newPaging,
                    page: state[gridName]?.page,
                },
            };
        },

        setingNewPageSize: (state: PagingSortingGridState, {payload}: PayloadAction<SetPageSizeAction>) => {
            const gridName = payload ? payload.gridName : '';
            const pageSize = payload ? payload.pageSize : 10;

            if (!state[gridName]) {
                // Grid info does not exist - do nothing
                // eslint-disable-next-line no-console
                console.warn(`SET_NEW_PAGE_SIZE: Grid data for '${gridName}' not found.`);
                return state;
            }

            const newPaging: GridPagingModel = {
                before: undefined,
                after: undefined,
                first: pageSize,
                last: undefined,
            };

            return {
                ...state,
                [gridName]: {
                    order: state[gridName]?.order,
                    paging: newPaging,
                    page: state[gridName]?.page,
                },
            };
        },

        goingPreviousPage: (state: PagingSortingGridState, {payload}: PayloadAction<GoPrevPageAction>) => {
            const gridName = payload ? payload.gridName : '';
            const before = payload ? payload.before : '';

            if (!state[gridName]) {
                // Grid info does not exist - do nothing
                // eslint-disable-next-line no-console
                console.warn(`GO_PREVIOUS_PAGE: Grid data for '${gridName}' not found.`);
                return state;
            }

            const newPaging: GridPagingModel = {
                before,
                after: undefined,
                first: undefined,
                last: state[gridName]?.paging.first !== undefined ? state[gridName]?.paging.first : state[gridName]?.paging.last,
            };

            return {
                ...state,
                [gridName]: {
                    order: state[gridName]?.order,
                    paging: newPaging,
                    page: state[gridName]?.page ?? 0 - 1,
                },
            };
        },

        goingNextPage: (state: PagingSortingGridState, {payload}: PayloadAction<GoNextPageAction>) => {
            const gridName = payload ? payload.gridName : '';
            const after = payload ? payload.after : '';

            if (!state[gridName]) {
                // Grid info does not exist - do nothing
                // eslint-disable-next-line no-console
                console.warn(`GO_NEXT_PAGE: Grid data for '${gridName}' not found.`);
                return state;
            }

            const newPaging: GridPagingModel = {
                before: undefined,
                after,
                first: state[gridName]?.paging.first !== undefined ? state[gridName]?.paging.first : state[gridName]?.paging.last,
                last: undefined,
            };

            return {
                ...state,
                [gridName]: {
                    order: state[gridName]?.order,
                    paging: newPaging,
                    page: state[gridName]?.page ?? 0 + 1,
                },
            };
        },

        goingFirstPage: (state: PagingSortingGridState, {payload}: PayloadAction<GoFirstPageAction>) => {
            const gridName = payload ? payload.gridName : '';

            if (!state[gridName]) {
                // Grid info does not exist - do nothing
                // eslint-disable-next-line no-console
                console.warn(`GO_FIRST_PAGE: Grid data for '${gridName}' not found.`);
                return state;
            }

            const newPaging: GridPagingModel = {
                before: undefined,
                after: undefined,
                first: state[gridName]?.paging.first !== undefined ? state[gridName]?.paging.first : state[gridName]?.paging.last,
                last: undefined,
            };

            return {...state, [gridName]: {paging: newPaging, order: state[gridName]?.order, page: 0}};
        },

        setingInitialData: (state: PagingSortingGridState, {payload}: PayloadAction<SetInitialDataAction>) => {
            const gridName = payload ? payload.gridName : '';
            const initialData = payload ? payload.initialData : undefined;

            if (state[gridName]) {
                // Already have data
                return state;
            }

            // Initialize data
            return {...state, [gridName]: initialData as PagingSortingGridStoreData};
        },

        clearingData: (state: PagingSortingGridState, {payload}: PayloadAction<ClearDataAction>) => {
            const gridName = payload ? payload.gridName : '';

            return {...state, [gridName]: undefined as any};
        },
    },
});

export const {clearingData, columnSortingClicked, goingFirstPage, goingNextPage, goingPreviousPage, setingInitialData, setingNewPageSize} =
    paginationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const pagination = (state: PagingSortingGridState) => state.pagination;

export default paginationSlice.reducer;

// Specifikace nepodporovaných browserů
export const browsers = /.*Chrome|Firefox|Safari.*/;

export const browserSupoort = (userAgent: string) => {
    const UA = userAgent.toLowerCase() ?? '';
    // const isIE = UA && /; msie|trident/i.test(UA);
    const isEdge = UA && /edg/i.test(UA);
    const isAndroid = UA && UA.indexOf('android') > 0;
    const isIOS = UA && /iphone|ipad|ipod|ios/i.test(UA);
    const isChrome = UA && /chrome|crios/i.test(UA) && !/opr|opera|chromium|edg|ucbrowser|googlebot|presto/i.test(UA);
    // const isGoogleBot = UA && /googlebot/i.test(UA);
    const isChromium = UA && /chromium/i.test(UA);
    // const isUcBrowser = UA && /ucbrowser/i.test(UA);
    const isSafari = UA && /safari/i.test(UA) && !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox|presto/i.test(UA);
    const isFirefox = UA && /firefox|fxios/i.test(UA) && !/seamonkey/i.test(UA);
    // const isOpera = UA && /opr|opera/i.test(UA);
    const isMobile = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    // const isSamsung = UA && /samsungbrowser/i.test(UA);
    const isIPad = UA && /ipad/.test(UA);
    const isIPhone = UA && /iphone/.test(UA);
    const isIPod = UA && /ipod/.test(UA);
    const isWebView = userAgent.includes('wv');
    const Distribuce24App = userAgent.includes('Distribuce24App');
    const isMac = UA && /macintosh|mac os x/i.test(UA);

    return (
        isEdge ||
        isAndroid ||
        isIOS ||
        isIPad ||
        isIPhone ||
        isIPod ||
        isChrome ||
        isChromium ||
        isSafari ||
        isFirefox ||
        isMobile ||
        isWebView ||
        Distribuce24App ||
        isMac
    );
};

// minimální velikost souboru
export const MIN_FILE_B_SIZE = 10;

// maximální velikost souboru
export const MAX_FILE_MB_SIZE = 5_000_000;

// maximální velikost souboru
export const MAX_FILE_B_SIZE = MAX_FILE_MB_SIZE * 1024 ** 2;

export const whiteSpace = new RegExp(/[  \u00A0\u1680\u180E\u2000-\u200B\u202F\u205F\u3000\uFEFF]/g);

export const FileAllowedTypeImport = ['gif', 'jpg', 'jpeg', 'tiff', 'png', 'pdf'];

export const PrilohaTyp = {
    /** Chyba */
    CHYBA: 'CHYBA',
    /** Ostatní */
    OSTATNI: 'OSTATNI',
    /** Registrace */
    REGISTRACE: 'REGISTRACE',
    /** Report ke stažení */
    REPORT: 'REPORT',
    /** Provozní report */
    REPORT_PROVOZ: 'REPORT_PROVOZ',
} as {[key: string]: string};

export type PrilohaTyp = (typeof PrilohaTyp)[keyof typeof PrilohaTyp];

export const statusCode = [401, 403, 503, 502];

export const name = 'x-access-token';
export const logged = 'di-acc-spp';

export const colors = [
    '#000080',
    '#008080',
    '#800000',
    '#800080',
    '#808000',
    '#808080',
    '#3f51b5',
    '#000040',
    '#0000c0',
    '#008040',
    '#0080c0',
    '#800040',
    '#8000c0',
    '#808040',
    '#8080c0',
    '#004000',
    '#004080',
    '#00c000',
    '#00c080',
    '#804000',
    '#804080',
    '#80c000',
    '#80c080',
    '#004040',
    '#0040c0',
    '#00c040',
    '#00c0c0',
    '#804040',
    '#8040c0',
    '#80c040',
    '#80c0c0',
    '#400000',
    '#400080',
    '#408000',
    '#408080',
    '#c00000',
    '#c00080',
    '#c08000',
    '#c08080',
    '#400040',
    '#4000c0',
    '#408040',
    '#4080c0',
    '#c00040',
    '#c000c0',
    '#c08040',
    '#c080c0',
    '#404000',
    '#404080',
    '#40c000',
    '#40c080',
    '#c04000',
    '#c04080',
    '#c0c000',
    '#c0c080',
    '#404040',
    '#4040c0',
    '#40c040',
    '#40c0c0',
    '#c04040',
    '#c040c0',
    '#c0c040',
    '#c0c0c0',
    '#000020',
    '#0000a0',
    '#008020',
    '#0080a0',
    '#800020',
    '#8000a0',
    '#808020',
    '#8080a0',
    '#000060',
    '#0000e0',
    '#008060',
    '#0080e0',
    '#800060',
    '#8000e0',
    '#808060',
    '#8080e0',
    '#004020',
    '#0040a0',
    '#00c020',
    '#00c0a0',
    '#804020',
    '#8040a0',
    '#80c020',
    '#80c0a0',
    '#004060',
    '#0040e0',
    '#00c060',
    '#00c0e0',
    '#804060',
    '#8040e0',
    '#80c060',
    '#80c0e0',
    '#400020',
    '#4000a0',
    '#408020',
    '#4080a0',
    '#c00020',
];

import {ReactNode} from 'react';
import {TooltipIcon} from '../../icons/TooltipIcon';
import {Div} from '../../styledComponents/Div';
import {Span} from '../../styledComponents/Span';

const DEFAULT_MAX_WIDTH = '100px';

type Props = {
    /**
     * Text to show
     */
    readonly content?: ReactNode | null;

    /**
     * Maximum text width (if you don't like the default)
     */
    readonly maxWidth?: string;
    /**
     * Open modal windows
     */
    readonly openModal?: () => void;
};

/**
 * Component that shows long text cell in table by showing just part and putting complete text into a tooltip.
 */
export const LongCellContent = ({content, maxWidth, openModal}: Props) => {
    if (!content || (typeof content === 'string' && content.length === 0)) {
        // No content
        return null;
    }

    return (
        <TooltipIcon
            htmlText={
                <Span
                    sx={{
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    {content}
                </Span>
            }
            placement="top"
            showIcon={false}
        >
            {
                <Div
                    style={{maxWidth: maxWidth ? maxWidth : DEFAULT_MAX_WIDTH}}
                    sx={{
                        display: 'block',
                        // whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        cursor: openModal ? 'pointer' : 'default',
                        textDecoration: openModal ? 'underline' : 'none',
                        color: openModal ? 'primary.main' : 'inherit',
                    }}
                    onClick={openModal}
                >
                    {content}
                </Div>
            }
        </TooltipIcon>
    );
};

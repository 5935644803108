import {WarningOutlined} from '@mui/icons-material';
import {Button, Paper, Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {Div} from '../../styledComponents/Div';
import {LandingBackground} from '../layout/LandingBackground';

type Props = {
    readonly handleReload: () => void;
    readonly errorMessage: string;
    readonly buttonTitle: string;
};

export const LoginErrorPage = ({handleReload, errorMessage, buttonTitle}: Props) => (
    <LandingBackground>
        <Paper elevation={16} sx={{borderRadius: 5}}>
            <Div
                sx={{
                    paddingTop: 2,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <WarningOutlined sx={{fontSize: 60}} color="error" />
            </Div>
            <Div
                sx={{
                    padding: 5,
                }}
            >
                <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    <FormattedMessage id={errorMessage} />
                </Typography>
            </Div>
            <Div
                sx={{
                    paddingBottom: 2,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Button variant="outlined" color="primary" onClick={handleReload}>
                    <FormattedMessage id={buttonTitle} />
                </Button>
            </Div>
        </Paper>
    </LandingBackground>
);

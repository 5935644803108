import {Ucet, UcetTyp} from '@eon.cz/gemini11-graphql';
import {Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {Div} from '../../styledComponents/Div';

type Props = {
    readonly onClick: () => void;
    readonly me: Ucet | undefined;
};

/**
 * Komponenta zobrazí informace o přihlášeném uživateli
 */
export const ProfileHeader = ({onClick, me}: Props) => (
    <Div
        sx={{
            cursor: 'pointer',
            padding: '5px 15px',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.1)',
            },
        }}
        onClick={onClick}
    >
        <Typography
            variant="subtitle1"
            sx={(theme) => ({
                color: theme.palette.grey[50],
                fontWeight: 600,
            })}
        >
            {me?.jmeno ? `${me?.jmeno} ${me?.prijmeni}` : <FormattedMessage id="user.undefined" />}{' '}
        </Typography>
        <Typography
            sx={(theme) => ({
                color: theme.palette.grey[50],
            })}
        >
            <FormattedMessage id="login" />
            {`: ${me?.email ? me.email : ''} |`} <FormattedMessage id="ucet.role" />
            {':'}
            {me?.typ === UcetTyp.SPRAVCE ? <FormattedMessage id="ucet.role.admin" /> : <FormattedMessage id="ucet.role.uzivatel" />}
        </Typography>
    </Div>
);

import Cookie from 'js-cookie';
import Router from 'next/router';
import {logged, name} from '../../common/constants';

/**
 * If the user is logged in, return true, otherwise return false
 * @returns A boolean value.
 */
export const isLogged = () => {
    if (typeof window !== 'undefined') {
        return !!Cookie.get(logged) && !!window.sessionStorage.getItem('logged_spp');
    }
};

/**
 * Clear auth cookie (= logout)
 */
export const removeCookie = () => {
    if (typeof window !== 'undefined') {
        window.sessionStorage.clear();
        window.localStorage.clear();
    }
    Cookie.remove(name, {secure: window.location.protocol === 'https:'});
};

/**
 * It returns the cookie value from the server request object
 * @param {any} req - The request object from the server.
 * @returns The cookie value from the server.
 */
export const getCookieFromServer = (req: any) => {
    return req?.cookies[name];
};

/**
 * Redirect correctly to given path
 *
 * @param path Path to redirect to
 * @param res Express response
 */
export const redirect = async (path: string, res?: any) => {
    if (res) {
        res.writeHead(302, {Location: path});
        res.end();
        res.writableFinished;
    } else {
        await Router.push(path);
    }
};
export const ErrorResponse = {
    TOKEN_INACTIVITY_EXCEEDED: 'TOKEN_INACTIVITY_EXCEEDED',
    INVALID_TOKEN: 'INVALID_TOKEN',
    ACCESS_DENIED: 'ACCESS_DENIED',
    SAP_ERROR: 'SAP_ERROR',
    LOGIN_DASABLED: 'LOGIN_DASABLED',
} as const;

export type ErrorResponse = (typeof ErrorResponse)[keyof typeof ErrorResponse];

/**
 * It returns a message based on the value of the error parameter
 * @param {ErrorResponse} error - ErrorResponse - the error response from the server
 * @returns A function that takes an error and returns a message.
 */
export const getErrorMessage = (error: ErrorResponse) => {
    const message: {[key in ErrorResponse]: string} = {
        [ErrorResponse.INVALID_TOKEN]: 'error.invalid.token',
        [ErrorResponse.TOKEN_INACTIVITY_EXCEEDED]: 'error.inactivity.token',
        [ErrorResponse.ACCESS_DENIED]: 'ucet.prihlaseni.error.login',
        [ErrorResponse.SAP_ERROR]: 'ucet.prihlaseni.error.login.sap',
        [ErrorResponse.LOGIN_DASABLED]: 'error.portal.odstaven',
    };

    return message[error] ?? 'error.revoked.token';
};

export type RoutePath = 'ROOT' | 'LOGIN' | 'ERROR' | 'WARNING' | 'PREHLED_SPOTREBY' | 'NASTAVENI_PARAMETRU' | 'OBSLUHA_PRODUKTU' | 'DOWNLOAD';

export type PageRouteModel = {
    readonly [key in RoutePath]: string;
};
export const PageRoute = {
    ROOT: '/',
    LOGIN: '/login',
    ERROR: '/error',
    WARNING: '/warning',
    DOWNLOAD: '/downloads',
    PREHLED_SPOTREBY: '/prehledSpotreby',
    NASTAVENI_PARAMETRU: '/nastaveniParametru',
    OBSLUHA_PRODUKTU: '/obsluhaProduktu',
} as PageRouteModel;

export type PageRoute = (typeof PageRoute)[keyof typeof PageRoute];

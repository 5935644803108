import type {Cell, Column} from 'exceljs';
import {saveAs} from 'file-saver';

export const EXPORT_DATUM_FORMAT = 'D.M.YYYY';
export const EXPORT_DATUM_CAS_FORMAT = 'D.M.YYYY H:mm';

/* A service for exporting data to excel. */
export const ExportService = {
    exportArrayOfArraysAsXLSX: async (items: any[][], filename: string, header: {title: string | undefined; subTitle: string | undefined}) => {
        const excelJS = await import('exceljs').then((mod) => mod);
        const workbook = new excelJS.Workbook();
        const {title, subTitle} = header;
        const worksheet = workbook.addWorksheet('Sheet 1');
        if (title) {
            worksheet.mergeCells('A1', 'B1');
            Object.assign(worksheet.getCell('A1'), {
                value: title,
                font: {
                    bold: true,
                    size: 30,
                },
                alignment: {
                    vertical: 'middle',
                    horizontal: 'left',
                    wrapText: true,
                },
            } as Cell);
            if (subTitle) {
                worksheet.mergeCells('A2', 'B2');
                Object.assign(worksheet.getCell('A2'), {
                    value: subTitle,
                    font: {
                        bold: true,
                        size: 20,
                    },
                    alignment: {
                        vertical: 'middle',
                        horizontal: 'left',
                        wrapText: true,
                    },
                } as Cell);
            }
        }

        worksheet.addRows(items);
        worksheet.columns.forEach((column) => {
            const lengths = column?.values?.map((v) => v?.toString().length ?? 0) ?? [];
            const maxLength = Math.max(...lengths?.filter((v) => typeof v === 'number'));
            Object.assign(column, {
                width: maxLength,
                numFmt: '@',
            } as Column);
        });
        await workbook.xlsx.writeBuffer({}).then((data) => {
            saveAs(new Blob([data]), filename);
        });
    },
};

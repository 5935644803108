import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Button, Grid, Typography} from '@mui/material';
import {useRouter} from 'next/router';
import {ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import {DrawerHeader} from '../../styledComponents/DrawerHeader';
import {redirectURI} from '../../utils/EnvironmentUtils';

type Props = {
    readonly children: ReactNode;
    readonly showComponent: boolean;
    readonly notActiveSPP: boolean;
};
export const Layout = ({children, showComponent, notActiveSPP}: Props) => {
    const {push} = useRouter();
    const handleBackToSelfcare = () => push(`${redirectURI}`);
    return (
        <>
            <DrawerHeader />
            {showComponent && children}
            {notActiveSPP && (
                <Grid container justifyContent="center" alignItems="center" sx={{marginTop: '30%'}}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h5" color="inherit">
                            <FormattedMessage id="dashboard.info" />
                        </Typography>
                        <Button sx={{marginTop: 2}} color="primary" variant="contained" disableRipple onClick={handleBackToSelfcare}>
                            <ArrowBackIcon />
                            <Typography variant="h6">
                                <FormattedMessage id="selfcare" />
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

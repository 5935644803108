import {styled} from '@mui/material/styles';
import {FC, ReactNode} from 'react';
import {useScreenOrientation} from '../../service/CommonService';

export const getDeviceType = () => {
    const ua = typeof navigator === 'undefined' ? '' : navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
    }
    if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return 'mobile';
    }
    return 'desktop';
};

type DeviceProps = {
    readonly orientation: 'portrait-primary' | 'landscape-primary' | 'landscape-secondary' | 'portrait-secondary';
    readonly app?: 'admin' | undefined;
};

const device = ({orientation, app = 'admin'}: DeviceProps) => {
    const dev = getDeviceType();
    switch (dev) {
        case 'desktop':
            if (orientation === 'portrait-primary') {
                return `url('/static/images/EGD_${app}_portrait.svg')`;
            }
            return `url('/static/images/EGD_${app}_landscape.svg')`;
        case 'mobile':
            if (orientation === 'landscape-primary') {
                return `url('/static/images/EGD_${app}_landscape.svg')`;
            }
            return `url('/static/images/EGD_${app}_mobile.svg')`;

        case 'tablet':
            if (orientation === 'portrait-primary') {
                return `url('/static/images/EGD_${app}_ipad_portrait.svg')`;
            }
            return `url('/static/images/EGD_${app}_ipad_landscape.svg')`;
    }
};

const DivWrapper = styled('div')<{readonly backgroundimage: string}>(({theme, backgroundimage}) => ({
    backgroundImage: backgroundimage,
    backgroundSize: '100% auto',
    backgroundColor: theme.palette.common.white,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(4)} 0`,
}));
const DivChildren = styled('div')(() => ({
    width: 'auto',
    display: 'flex',
    margin: 'auto',
}));

type Props = {
    readonly children: ReactNode;
};

/**
 * Wrapper komponenta obsahující pozadí aplikace
 */
export const LandingBackground: FC<Props> = ({children}) => {
    const orientation = useScreenOrientation();

    const backgroundImage = device({orientation});

    return (
        <DivWrapper backgroundimage={backgroundImage}>
            <DivChildren role="main">{children}</DivChildren>
        </DivWrapper>
    );
};

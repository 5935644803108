import {ReactNode} from 'react';
import {Div} from './Div';

type Props = {
    readonly children?: ReactNode;
};
export const DrawerHeader = ({children}: Props) => (
    <Div
        sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        })}
    >
        {children}
    </Div>
);

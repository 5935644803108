import {ApiDate, EanAdresa, Interval as IntervalQraphQL, NazevNaseptavac, SkupinaNaseptavac, Velicina} from '@eon.cz/gemini11-graphql';
import {
    addDays,
    addMonths,
    differenceInDays,
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
    subDays,
    subMonths,
    subYears,
} from 'date-fns';

export const PREHLED_SPOTREBY_OM = 'PREHLED_SPOTREBY_OM';
export const PREHLED_ANOMALIE_OM = 'PREHLED_ANOMALIE_OM';

export const Interval = {
    YESTERDAY: 'YESTERDAY',
    LAST_WEEK: 'LAST_WEEK',
    LAST_7_DAYS: 'LAST_7_DAYS',
    LAST_30_DAYS: 'LAST_30_DAYS',
    LAST_MONTH: 'LAST_MONTH',
    LAST_3_MONTH: 'LAST_3_MONTH',
    LAST_6_MONTH: 'LAST_6_MONTH',
    LAST_12_MONTH: 'LAST_12_MONTH',
    LAST_YEAR: 'LAST_YEAR',
    THIS_YEAR: 'THIS_YEAR',
} as const;

export type Interval = (typeof Interval)[keyof typeof Interval];

export const casovyInterval = [
    {
        description: 'Včera',
        value: Interval.YESTERDAY,
        table: false,
        graf: true,
        anomalie: true,
    },
    {
        description: 'Minulý týden',
        value: Interval.LAST_WEEK,
        table: false,
        graf: true,
        anomalie: true,
    },
    {
        description: 'Posledních 7 dní',
        value: Interval.LAST_7_DAYS,
        table: false,
        graf: true,
        anomalie: true,
    },
    {
        description: 'Minulý měsíc',
        value: Interval.LAST_MONTH,
        table: false,
        graf: true,
        anomalie: true,
    },
    {
        description: 'Posledních 30 dnů',
        value: Interval.LAST_30_DAYS,
        table: false,
        graf: true,
        anomalie: true,
    },
    {
        description: 'Poslední 3 měsíce',
        value: Interval.LAST_3_MONTH,
        table: true,
        graf: true,
        anomalie: true,
    },
    {
        description: 'Posledních 6 měsíců',
        value: Interval.LAST_6_MONTH,
        table: true,
        graf: true,
        anomalie: true,
    },
    {
        description: 'Posledních 12 měsíců',
        value: Interval.LAST_12_MONTH,
        table: true,
        graf: true,
        anomalie: true,
    },
    {
        description: 'Minulý rok',
        value: Interval.LAST_YEAR,
        table: true,
        graf: true,
        anomalie: true,
    },
    {
        description: 'Stávající rok',
        value: Interval.THIS_YEAR,
        table: true,
        graf: true,
        anomalie: false,
    },
];

/**
 * It takes an interval and returns an object with from and to properties
 * @param {Interval | undefined} interval - Interval | undefined
 * @returns An object with two keys, from and to, and two values, the start and end of the interval.
 */
export const remapInterval = (interval: Interval | undefined) => {
    const casovyInterval = {
        [Interval.YESTERDAY]: {from: format(startOfDay(addDays(new Date(), -1)), 'yyyy-MM-dd'), to: format(endOfDay(addDays(new Date(), -1)), 'yyyy-MM-dd')},
        [Interval.LAST_WEEK]: {
            from: format(startOfWeek(addDays(new Date(), -7), {weekStartsOn: 1}), 'yyyy-MM-dd'),
            to: format(endOfWeek(addDays(new Date(), -7), {weekStartsOn: 1}), 'yyyy-MM-dd'),
        },
        [Interval.LAST_7_DAYS]: {from: format(subDays(new Date(), 7), 'yyyy-MM-dd'), to: format(subDays(new Date(), 1), 'yyyy-MM-dd')},
        [Interval.LAST_MONTH]: {
            from: format(startOfMonth(addMonths(new Date(), -1)), 'yyyy-MM-dd'),
            to: format(endOfMonth(addMonths(new Date(), -1)), 'yyyy-MM-dd'),
        },
        [Interval.LAST_30_DAYS]: {from: format(subMonths(new Date(), 1), 'yyyy-MM-dd'), to: format(subDays(new Date(), 1), 'yyyy-MM-dd')},
        [Interval.LAST_3_MONTH]: {from: format(subMonths(new Date(), 3), 'yyyy-MM-dd'), to: format(subDays(new Date(), 1), 'yyyy-MM-dd')},
        [Interval.LAST_6_MONTH]: {from: format(subMonths(new Date(), 6), 'yyyy-MM-dd'), to: format(subDays(new Date(), 1), 'yyyy-MM-dd')},
        [Interval.LAST_12_MONTH]: {from: format(subMonths(new Date(), 12), 'yyyy-MM-dd'), to: format(subDays(new Date(), 1), 'yyyy-MM-dd')},
        [Interval.LAST_YEAR]: {from: format(startOfYear(subYears(new Date(), 1)), 'yyyy-MM-dd'), to: format(endOfYear(subYears(new Date(), 1)), 'yyyy-MM-dd')},
        [Interval.THIS_YEAR]: {from: format(startOfYear(new Date()), 'yyyy-MM-dd'), to: format(subDays(new Date(), 1), 'yyyy-MM-dd')},
    };

    return casovyInterval[interval ?? Interval.THIS_YEAR];
};

export const Agregation = {
    CTVRTHODINA: 'CTVRTHODINA',
    HODINA: 'HODINA',
    DEN: 'DEN',
    TYDEN: 'TYDEN',
    MESIC: 'MESIC',
    ROK: 'ROK',
} as const;

export type Agregation = (typeof Agregation)[keyof typeof Agregation];

export const ChartType = {
    PIE: 'pie',
    BAR: 'column',
    LINE: 'line',
} as const;

export type ChartType = (typeof ChartType)[keyof typeof ChartType];

/**
 * It takes an interval and a custom interval and returns an array of agregations
 * @param  - Interval - the interval of the data to be displayed
 * @returns An array of Agregation
 */
export const remapAgregace = ({
    interval,
    selectCustomDate,
    customInterval,
    typGrafu,
    all,
}: {
    interval: Interval | undefined;
    selectCustomDate: boolean | undefined;
    customInterval: CustomInterval | undefined;
    typGrafu: ChartType;
    all: boolean;
}): Agregation[] => {
    if (all && interval && !selectCustomDate) {
        const agregace = {
            [Interval.YESTERDAY]: [Agregation.CTVRTHODINA, Agregation.HODINA],
            [Interval.LAST_WEEK]: [Agregation.CTVRTHODINA, Agregation.HODINA, Agregation.DEN],
            [Interval.LAST_7_DAYS]: [Agregation.CTVRTHODINA, Agregation.HODINA, Agregation.DEN],
            [Interval.LAST_MONTH]: [Agregation.CTVRTHODINA, Agregation.HODINA, Agregation.DEN, Agregation.TYDEN],
            [Interval.LAST_30_DAYS]: [Agregation.CTVRTHODINA, Agregation.HODINA, Agregation.DEN, Agregation.TYDEN],
            [Interval.LAST_3_MONTH]: [Agregation.CTVRTHODINA, Agregation.HODINA, Agregation.DEN, Agregation.TYDEN, Agregation.MESIC],
            [Interval.LAST_6_MONTH]: [Agregation.CTVRTHODINA, Agregation.DEN, Agregation.TYDEN, Agregation.MESIC],
            [Interval.LAST_12_MONTH]: [Agregation.CTVRTHODINA, Agregation.DEN, Agregation.TYDEN, Agregation.MESIC],
            [Interval.LAST_YEAR]: [Agregation.CTVRTHODINA, Agregation.DEN, Agregation.TYDEN, Agregation.MESIC],
            [Interval.THIS_YEAR]: [Agregation.CTVRTHODINA, Agregation.DEN, Agregation.TYDEN, Agregation.MESIC],
        };
        return agregace[interval];
    }
    if (all && selectCustomDate && customInterval) {
        const remapCustomIntervalToInterval = differenceInDays(customInterval?.endDate, customInterval?.startDate);
        const agregace = {
            1: [Agregation.CTVRTHODINA, Agregation.HODINA],
            7: [Agregation.CTVRTHODINA, Agregation.HODINA, Agregation.DEN],
            186: [Agregation.CTVRTHODINA, Agregation.HODINA, Agregation.DEN, Agregation.MESIC],
            365: [Agregation.CTVRTHODINA, Agregation.HODINA, Agregation.DEN, Agregation.MESIC],
        };
        const rozsah = remapCustomIntervalToInterval <= 1 ? 1 : remapCustomIntervalToInterval <= 7 ? 7 : remapCustomIntervalToInterval <= 186 ? 186 : 365;
        return agregace[rozsah];
    }
    if (typGrafu === ChartType.PIE) {
        return [Agregation.DEN, Agregation.MESIC, Agregation.ROK];
    }
    if (selectCustomDate && customInterval) {
        const remapCustomIntervalToInterval = differenceInDays(customInterval?.endDate, customInterval?.startDate);
        const agregace = {
            1: [Agregation.CTVRTHODINA, Agregation.HODINA],
            7: [Agregation.CTVRTHODINA, Agregation.HODINA, Agregation.DEN],
            186: [Agregation.DEN],
            365: [Agregation.TYDEN, Agregation.MESIC],
        };
        const rozsah = remapCustomIntervalToInterval <= 1 ? 1 : remapCustomIntervalToInterval <= 7 ? 7 : remapCustomIntervalToInterval <= 186 ? 186 : 365;
        return agregace[rozsah];
    }

    if (!selectCustomDate && interval) {
        const agregace = {
            [Interval.YESTERDAY]: [Agregation.CTVRTHODINA, Agregation.HODINA],
            [Interval.LAST_WEEK]: [Agregation.CTVRTHODINA, Agregation.HODINA, Agregation.DEN],
            [Interval.LAST_7_DAYS]: [Agregation.CTVRTHODINA, Agregation.HODINA, Agregation.DEN],
            [Interval.LAST_MONTH]: [Agregation.HODINA, Agregation.DEN, Agregation.TYDEN],
            [Interval.LAST_30_DAYS]: [Agregation.HODINA, Agregation.DEN, Agregation.TYDEN],
            [Interval.LAST_3_MONTH]: [Agregation.HODINA, Agregation.DEN, Agregation.TYDEN, Agregation.MESIC],
            [Interval.LAST_6_MONTH]: [Agregation.DEN, Agregation.TYDEN, Agregation.MESIC],
            [Interval.LAST_12_MONTH]: [Agregation.DEN, Agregation.TYDEN, Agregation.MESIC],
            [Interval.LAST_YEAR]: [Agregation.DEN, Agregation.TYDEN, Agregation.MESIC],
            [Interval.THIS_YEAR]: [Agregation.DEN, Agregation.TYDEN, Agregation.MESIC],
        };
        return agregace[interval];
    }

    return [Agregation.CTVRTHODINA, Agregation.HODINA, Agregation.DEN, Agregation.TYDEN, Agregation.MESIC];
};

export type CustomInterval = {
    startDate: Date;
    endDate: Date;
    key: 'selection';
};
export type GrafFilterType = {
    interval?: Interval;
    customInterval?: CustomInterval;
    omIds: NazevNaseptavac[] | undefined;
    eans: EanAdresa[] | undefined;
    nazevSkupinyOM: SkupinaNaseptavac[] | undefined;
    selectCustomDate?: boolean;
    typGrafu: ChartType;
    velicina: Velicina;
    agregace?: Agregation;
};
export type AnomalieFilterType = {
    interval?: Interval;
    customInterval?: CustomInterval;
    eans?: EanAdresa[] | undefined;
    omIds?: NazevNaseptavac[] | undefined;
    nazevSkupinyOM?: SkupinaNaseptavac[] | undefined;
    selectCustomDate?: boolean;
};

export const defaultAnomalieFilterValues: Partial<AnomalieFilterType> = {
    eans: undefined,
    omIds: undefined,
    interval: Interval.YESTERDAY,
    customInterval: {
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), -1),
        key: 'selection',
    },
    nazevSkupinyOM: undefined,
    selectCustomDate: false,
};
export const defaultGrafFilterValues: GrafFilterType = {
    omIds: undefined,
    eans: undefined,
    interval: Interval.YESTERDAY,
    customInterval: {
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), -1),
        key: 'selection',
    },
    nazevSkupinyOM: undefined,
    selectCustomDate: false,
    typGrafu: ChartType.LINE,
    velicina: Velicina.SPOTREBA,
    agregace: Agregation.CTVRTHODINA,
};

/**
 * It takes an interval and returns an object with from and to dates
 * @param {Interval | CustomInterval | undefined} interval - Interval | CustomInterval | undefined
 * @returns An object with two properties, from and to.
 */
export const transformInterval = (interval: Interval | CustomInterval | undefined): IntervalQraphQL => {
    if (typeof interval === 'object') {
        return {
            from: format(interval.startDate, 'yyyy-MM-dd') as unknown as ApiDate,
            to: format(interval.endDate, 'yyyy-MM-dd') as unknown as ApiDate,
        };
    }
    const {from, to} = remapInterval(interval);
    return {from: from as unknown as ApiDate, to: to as unknown as ApiDate};
};

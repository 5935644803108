import {SvgIcon, SvgIconProps} from '@mui/material';

type Props = SvgIconProps;

export const UserIcon = (props: Props) => (
    <SvgIcon {...props}>
        <path d="M 12.04 1.739 C 14.99 1.739 16.836 4.934 15.359 7.49 C 13.883 10.046 10.194 10.046 8.719 7.49 C 8.383 6.907 8.205 6.246 8.205 5.573 C 8.208 3.457 9.923 1.742 12.04 1.739 M 12.04 0.461 C 8.104 0.461 5.645 4.722 7.613 8.128 C 9.581 11.536 14.499 11.536 16.466 8.128 C 16.915 7.352 17.15 6.47 17.15 5.573 C 17.15 2.75 14.862 0.461 12.04 0.461 Z" />
        <path d="M 18.513 13.24 L 18.513 15.796 C 18.513 20.713 13.189 23.787 8.93 21.328 C 6.953 20.187 5.736 18.077 5.736 15.796 L 5.736 13.24 L 18.513 13.24 M 19.791 11.962 L 4.458 11.962 L 4.458 15.796 C 4.458 20.028 7.891 23.461 12.123 23.461 L 12.123 23.461 C 16.357 23.461 19.791 20.028 19.791 15.796 L 19.791 11.962 Z" />
    </SvgIcon>
);

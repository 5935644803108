import {gql} from '@apollo/client/core';

export const CommonQueries = {
    gql: {
        me: gql`
            query Me {
                me {
                    ucet {
                        email
                        jmeno
                        prijmeni
                        pristupy {
                            odbernaMista {
                                elektrina {
                                    inherit
                                    oms
                                }
                                plyn {
                                    inherit
                                    oms
                                }
                            }
                            skupina {
                                cislo
                                nazev
                                typ
                            }
                        }
                        typ
                    }
                    produkt {
                        produktStav
                        distribucniSmlouvaStav
                        aktivniOm
                    }
                    skupina {
                        cislo
                        nazev
                        typ
                    }
                }
            }
        `,
    },
};

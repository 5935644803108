import {useQuery} from '@apollo/client';
import {ProduktStav, Query, UcetTyp} from '@eon.cz/gemini11-graphql';
import {PowerSettingsNew as LogoutIcon, Menu as MenuIcon} from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Box, Button, Drawer, IconButton, Toolbar, Typography} from '@mui/material';
import {useRouter} from 'next/router';
import {FC, ReactNode, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {LoginDisabled} from '../../../auth/components/LoginDisabled';
import {ErrorResponse, getErrorMessage, isLogged} from '../../../auth/service/LoginService';
import {Store} from '../../../lib/StoreType';
import {apolloClient} from '../../../lib/apolloClient';
import {useTheme} from '../../../lib/theme';
import {PageRoute} from '../../PageRouteModel';
import {useCommonAction} from '../../action/CommonAction';
import {CommonQueries} from '../../graphql/CommonQueries';
import {UserIcon} from '../../icons/UserIcon';
import {hasZasmluvneno} from '../../service/CommonService';
import {AppBar} from '../../styledComponents/Appbar';
import {Div} from '../../styledComponents/Div';
import {sanitizeHTML, transformPath, useMatches} from '../../utils/CommonUtils';
import {LoadingDialog} from '../dialogs/LoadingDialog';
import {LoginErrorPage} from '../login/LoginErrorPage';
import {ProfileDetailSideDrawer} from '../login/ProfileDetailSideDrawer';
import {useAddNotification} from '../notifications/actions/NotificationsActions';
import {NotificationsComponent} from '../notifications/components/NotificationsComponent';
import {AppLeftMenu} from './AppLeftMenu';
import {EgdLogo} from './EgdLogo';
import {LandingBackground} from './LandingBackground';
import {Layout} from './Layout';
import {ProfileHeader} from './ProfileHeader';

type Props = {
    readonly statusCode: number;
    readonly children: ReactNode;
};

export const PageLayout: FC<Props> = ({children, statusCode}) => {
    // methods
    const matches = useMatches();

    const {
        push,
        pathname,
        query: {error},
    } = useRouter();
    const {theme} = useTheme();
    const {closeNotification} = useAddNotification();
    const {logout, fetchMe, setProdukt, setMe, fetchLoginConfig, backToD24} = useCommonAction();
    const backToLogin = () => push({pathname: PageRoute.LOGIN});
    const handleOnClickLogoutWithError = (error?: ErrorResponse) => () => logout(apolloClient, error);

    // states
    const {notifications} = useSelector((state: Store) => state?.notifications);
    const {logouting, stopChangeDefaultPath, isFetchingLogin, loginConfigDisabled, selfcareLogin} = useSelector((state: Store) => state?.common);

    const [openLeftMenu, setOpenLeftMenu] = useState(false);
    const [openRightProfileDrawer, setOpenRightProfileDrawer] = useState(false);
    const [notActiveSPP, setNotActiveSPP] = useState(false);

    // local variables
    const path = transformPath(pathname);

    // Proměnná pro zobrazení stepperu a rozcestníku v Appbaru
    const showComponent = !([404, 500].includes(statusCode) || pathname === '/_error') || !notActiveSPP;

    // get query data
    const {data, loading} = useQuery<Query, {hasZasmluvneno: boolean}>(CommonQueries.gql.me, {
        variables: {hasZasmluvneno: false},
        fetchPolicy: 'no-cache',
        onError: ({networkError}) => {
            const status = networkError && 'statusCode' in networkError && networkError?.statusCode;
            const statusError = status === 401 ? ErrorResponse.ACCESS_DENIED : status === 503 ? ErrorResponse.LOGIN_DASABLED : ErrorResponse.SAP_ERROR;
            handleOnClickLogoutWithError(statusError)();
        },
        onCompleted: (res) => {
            const isAdmin = res.me.ucet.typ === UcetTyp.SPRAVCE;
            const isAktivniOM = res.me.produkt.aktivniOm;
            const isEnergetik = res.me.produkt.produktStav === ProduktStav.ZMOCNENEC;
            setProdukt(res.me.produkt);
            setMe(res.me);
            const zasmluvneno = hasZasmluvneno(res.me.produkt.produktStav) && isAktivniOM;
            if (isEnergetik && !stopChangeDefaultPath) {
                if (res.me.produkt.aktivniOm) {
                    push({pathname: PageRoute.PREHLED_SPOTREBY}).then(() => {
                        fetchMe();
                    });
                } else {
                    setNotActiveSPP(true);
                }
                return;
            }
            if (zasmluvneno && !stopChangeDefaultPath && isAdmin) {
                push({pathname: PageRoute.PREHLED_SPOTREBY}).then(() => {
                    fetchMe();
                });
            } else if (isAdmin && !stopChangeDefaultPath) {
                push({pathname: PageRoute.OBSLUHA_PRODUKTU}).then(() => {
                    fetchMe();
                });
            } else if (!zasmluvneno && !isAdmin && !isEnergetik) {
                setNotActiveSPP(true);
            } else if (isAdmin && !zasmluvneno) {
                push({pathname: PageRoute.OBSLUHA_PRODUKTU}).then(() => {
                    fetchMe();
                });
            } else if (!stopChangeDefaultPath) {
                push({pathname: PageRoute.PREHLED_SPOTREBY}).then(() => {
                    fetchMe();
                });
            }
        },
        skip: !isLogged(),
    });

    useEffect(() => {
        fetchLoginConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (error === ErrorResponse.LOGIN_DASABLED || loginConfigDisabled) {
        return <LoginDisabled />;
    }

    if (isFetchingLogin) {
        return <LoadingDialog open={isFetchingLogin ?? false} />;
    }

    if (error) {
        const errorMessage = getErrorMessage(error as ErrorResponse);

        return <LoginErrorPage errorMessage={errorMessage} buttonTitle={'login.zpet.na.prihlaseni'} handleReload={backToLogin} />;
    }

    if (logouting) {
        return (
            <LandingBackground>
                <LoadingDialog open={true} description={'logouting'} />
            </LandingBackground>
        );
    }

    // query data
    const me = data?.me;

    const canShowNastaveniParateru = me?.produkt?.aktivniOm;

    // functions
    const handleChangeRoute = () => pathname !== PageRoute.ROOT && push({pathname: PageRoute.ROOT}, undefined, {shallow: true});
    const handleOnOpenLeftMenu = () => setOpenLeftMenu(true);
    const handleOnCloseLeftMenu = () => setOpenLeftMenu(false);
    const handleOnOpenRightProfileDrawer = () => {
        handleOnCloseLeftMenu();
        setOpenRightProfileDrawer(true);
    };

    const handleOnCloseRightProfileDrawer = () => setOpenRightProfileDrawer(false);

    const handleBackToSelfcare = () => backToD24(apolloClient);

    if (isLogged()) {
        return (
            <Div
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                }}
            >
                {/* Zobrazení notifikací */}
                <NotificationsComponent notifications={notifications} onClose={closeNotification} />

                {/* Zobrazení appbaru */}
                <AppBar
                    position="fixed"
                    open={matches ? undefined : openLeftMenu}
                    sx={{background: 'linear-gradient(270deg,rgba(225,48,25,0.86) 0%,rgba(225,48,25,0.27) 100%)', backgroundColor: 'primary.dark'}}
                >
                    <Toolbar
                        sx={{
                            marginLeft: 2,
                            height: 96,
                            minHeight: 72,
                        }}
                    >
                        <IconButton
                            sx={{
                                marginRight: 4,
                                ...(openLeftMenu && {display: 'none'}),
                                '&:hover': {
                                    color: 'inherit',
                                },
                                color: 'background.paper',
                            }}
                            color="inherit"
                            aria-label="Menu"
                            onClick={handleOnOpenLeftMenu}
                        >
                            <MenuIcon />

                            {!matches && (
                                <>
                                    &nbsp;
                                    <FormattedMessage id="menu" />
                                </>
                            )}
                        </IconButton>
                        <Box sx={{display: {xs: 'none'}}}>
                            <IconButton
                                sx={{
                                    marginLeft: 5,
                                }}
                                onClick={handleChangeRoute}
                            >
                                <EgdLogo width={150} height={50} pathFill={theme.palette.background.paper} />
                            </IconButton>
                        </Box>
                        <Typography
                            variant={matches ? 'h6' : 'h4'}
                            color="inherit"
                            sx={{
                                marginLeft: matches ? 1 : 5,
                            }}
                            dangerouslySetInnerHTML={{__html: sanitizeHTML(path)}}
                        />

                        {!matches && selfcareLogin && (
                            <Button sx={{marginLeft: 2}} color="inherit" variant="outlined" onClick={handleBackToSelfcare}>
                                <ArrowBackIcon />
                                <Typography variant="h6">
                                    <FormattedMessage id="selfcare" />
                                </Typography>
                            </Button>
                        )}
                        <Box sx={{flexGrow: 1}} />
                        <Box sx={{display: {xs: 'none', md: 'flex', alignItems: 'center'}}}>
                            <ProfileHeader me={me?.ucet} onClick={handleOnOpenRightProfileDrawer} />
                            <Box sx={{display: {sm: 'none', md: 'none', lg: 'flex', alignItems: 'center'}}}>
                                <UserIcon sx={{fontSize: 24}} />
                                <IconButton
                                    sx={{
                                        '&:hover': {
                                            color: 'background.default',
                                        },
                                        color: 'background.paper',
                                    }}
                                    aria-label="Odhlaseni"
                                    onClick={handleOnClickLogoutWithError()}
                                >
                                    <LogoutIcon sx={{fontSize: 24}} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
                <AppLeftMenu
                    theme={theme}
                    open={openLeftMenu}
                    canShow={canShowNastaveniParateru}
                    onClose={handleOnCloseLeftMenu}
                    me={me}
                    handleOnOpenRightProfileDrawer={handleOnOpenRightProfileDrawer}
                />
                <Drawer open={openRightProfileDrawer} anchor="right" onClose={handleOnCloseRightProfileDrawer}>
                    {openRightProfileDrawer && me && <ProfileDetailSideDrawer ucet={me} />}
                </Drawer>
                {/* Zobrazení obsahu */}
                <Box component="main" sx={{flexGrow: 1, paddingTop: matches ? 5 : 4, overflowX: 'hidden'}}>
                    {loading ? (
                        <LoadingDialog open />
                    ) : (
                        <Layout showComponent={showComponent} notActiveSPP={notActiveSPP}>
                            {children}
                        </Layout>
                    )}
                </Box>
            </Div>
        );
    }
    return children;
};

import {Context, UcetTyp} from '@eon.cz/gemini11-graphql';
import {AlternateEmail as LoginIcon, Description as DecriptionIcon, Person as PersonIcon, Work as WorkIcon} from '@mui/icons-material';
import {Avatar, Divider, Grid, Typography} from '@mui/material';
import {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {Div} from '../../styledComponents/Div';

type Props = {
    readonly ucet: Context;
};

/**
 * Komponenta zobrazí profilovou stránku uživatele
 */
export const ProfileDetailSideDrawer: FC<Props> = ({ucet}) => {
    const jmeno = ucet?.ucet?.jmeno.charAt(0);
    const prijmeni = ucet?.ucet?.prijmeni.charAt(0);

    return (
        <Div
            sx={{
                width: 300,
            }}
        >
            <Grid container spacing={0}>
                <Grid
                    container
                    spacing={0}
                    sx={{
                        background: 'linear-gradient(270deg,rgba(225,48,25,0.86) 0%,rgba(225,48,25,0.27) 100%)',
                        backgroundColor: 'primary.dark',
                        paddingBottom: '10px',
                    }}
                >
                    <Grid item xs={12}>
                        <Avatar
                            sx={{
                                width: '110px',
                                height: '110px',
                                margin: '30px auto',
                                fontSize: '50px',
                                backgroundColor: 'common.white',
                                color: 'primary.main',
                            }}
                        >
                            {jmeno ? (
                                `${jmeno} ${prijmeni}`
                            ) : (
                                <PersonIcon
                                    sx={{
                                        color: 'primary.main',
                                        fontSize: '100px',
                                    }}
                                />
                            )}
                        </Avatar>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'inline-flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography style={{color: 'white'}} variant="h5">
                            {jmeno ? `${jmeno} ${prijmeni}` : <FormattedMessage id="pozadavek.neni.vyplneno" />}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'inline-flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                color: 'common.white',
                            }}
                            variant="subtitle1"
                        >
                            {ucet?.skupina?.nazev}
                        </Typography>
                    </Grid>
                    {ucet?.skupina?.typ && (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'inline-flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: 'common.white',
                                }}
                                variant="subtitle1"
                            >
                                <FormattedMessage id={`ucet.skupina.typ.${ucet?.skupina?.typ.toUpperCase()}`} />
                            </Typography>
                        </Grid>
                    )}
                    <Typography
                        sx={{
                            border: '2px solid',
                            borderColor: 'primary.main',
                            borderRadius: '60px',
                            position: 'relative',
                            margin: 'auto',
                            top: '25px',
                            padding: '0 10px',
                            backgroundColor: 'white',
                            color: 'black',
                        }}
                        variant="subtitle1"
                    >
                        <FormattedMessage
                            id={
                                ucet?.ucet?.typ === UcetTyp.SPRAVCE
                                    ? 'ucet.typ.administrator'
                                    : 'pristupy' in ucet?.ucet && ucet?.ucet?.pristupy
                                      ? 'ucet.typ.energetik'
                                      : 'ucet.typ.ucet'
                            }
                        />
                    </Typography>
                </Grid>

                <Divider />

                <Grid
                    container
                    spacing={0}
                    sx={{
                        marginTop: 5,
                        marginBottom: 5,
                        marginLeft: 2,
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginBottom: 1,
                        }}
                    >
                        <Typography variant="overline">
                            <FormattedMessage id="ucet.prihlaseni.uzivatel" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            <LoginIcon
                                sx={(theme) => ({
                                    color: theme.palette.grey[600],
                                    position: 'relative',
                                    top: 7,
                                })}
                            />
                            &nbsp;&nbsp;{ucet?.ucet?.email}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid
                    container
                    spacing={0}
                    sx={{
                        marginTop: 2,
                        marginBottom: 5,
                        marginLeft: 2,
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginBottom: 1,
                        }}
                    >
                        <Typography variant="overline">
                            <FormattedMessage id="kontakt.obchodni.partner" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            <WorkIcon
                                sx={(theme) => ({
                                    color: theme.palette.grey[600],
                                    position: 'relative',
                                    top: 7,
                                })}
                            />
                            &nbsp;&nbsp;&nbsp;{ucet?.skupina?.nazev}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            <DecriptionIcon
                                sx={(theme) => ({
                                    color: theme.palette.grey[600],
                                    position: 'relative',
                                    top: 7,
                                })}
                            />
                            &nbsp;&nbsp;&nbsp;{ucet?.skupina?.cislo}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Div>
    );
};

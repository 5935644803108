import {useMediaQuery} from '@mui/material';
import {Maybe} from 'graphql/jsutils/Maybe';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import omitBy from 'lodash/omitBy';
import {ReactNode} from 'react';
import sanitizeHtml from 'sanitize-html';

export const nullAsEmpty = (s: string | null | undefined | Maybe<string>): string => (typeof s === 'string' ? s : '');
export const isNullOrUndefined = <T extends Record<string, unknown> | string | null | ReactNode>(val: T) => val == null;

export const isNullOrUndefinedOrEmpty = <T extends Record<string, unknown> | string | null | ReactNode>(val: T) =>
    isNullOrUndefined(val) || val === '' || val === 'undefined';

export const isNotNullOrUndefinedOrEmpty = <T extends Record<string, unknown>>(val: T) => !isNullOrUndefinedOrEmpty(val);
/**
 * Parse string to number with three decimal places
 *
 * @param {(number | string)} value
 * @return {*}
 */
export const parseDecimal = (value: number | string | undefined | null, fraction = 3): number =>
    typeof value === 'number' ? value : Number(parseFloat(value as string).toFixed(fraction));

/**
 * It returns true if the two objects are equal, except for functions
 * @param {T} prevProps - The previous props that were passed to the component.
 * @param {T} nextProps - The next props that will be received by the component.
 * @returns A function that takes two arguments, prevProps and nextProps, and returns a boolean.
 */
export const areEqual = <T extends Record<string, unknown>>(prevProps: T, nextProps: T): boolean => {
    const [prev, next] = [prevProps, nextProps].map((props) => omitBy(props, isFunction));
    return isEqual(prev, next);
};

/**
 * Transform path
 * @param {string} pathname
 */
export const transformPath = (pathname: string): string =>
    pathname === '/' ? 'Spotřeba pod palcem' : (path[pathname] as string)?.replace(/([a-zA-Za-žA-Ž ]+)[.!?]?\s*$/, '<strong>$1</strong>');

const path = {
    '/': 'Spotřeba pod palcem',
    '/obsluhaProduktu': 'Obsluha produktu',
    '/nastaveniParametru': 'Nastavení parametrů',
    '/prehledSpotreby': 'Přehled spotřeby',
    '/_error': 'Nenalezeno',
} as {readonly [key: string]: string};

export const useMatches = (maxWidth = '500px') => useMediaQuery(`(max-width:${maxWidth})`);

export const selectedPath = (path: string, canShow: Maybe<boolean> | undefined) =>
    typeof canShow !== 'undefined' && !canShow
        ? numberOfPath.filter((pathname) => pathname !== '/nastaveniParametru' && pathname === path).findIndex((pathname) => path === pathname)
        : numberOfPath.findIndex((pathname) => path === pathname);

const numberOfPath = ['/prehledSpotreby', '/nastaveniParametru', '/obsluhaProduktu'];

/**
 * It takes a string of HTML and returns a string of HTML that's been sanitized
 * @param {string} html - The HTML string to sanitize.
 */
export const sanitizeHTML = (html: string) => sanitizeHtml(html);

/**
 * It returns a promise that resolves after a given number of milliseconds
 * @param {number} ms - number - The number of milliseconds to wait.
 */
export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

// Weights of prefixes
export const prefix_weights = [10, 5, 8, 4, 2, 1];

// Weights of bank account body
export const base_weights = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];

// All banks in CZ
export const banks = {
    '0100': 'Komerční banka, a.s.',
    '0300': 'Československá obchodní banka, a.s.',
    '0600': 'MONETA Money Bank, a.s.',
    '0710': 'Česká národní banka',
    '0800': 'Česká spořitelna, a.s.',
    '2010': 'Fio banka, a.s.',
    '2020': 'MUFG Bank (Europe) N.V. Prague Branch',
    '2030': 'Československé úvěrní družstvo',
    '2060': 'Citfin, spořitelní družstvo',
    '2070': 'TRINITY BANK, a.s.',
    '2100': 'Hypoteční banka, a.s.',
    '2200': 'Peněžní dům, spořitelní družstvo',
    '2220': 'Artesa, spořitelní družstvo',
    '2250': 'Banka CREDITAS a.s.',
    '2260': 'NEY spořitelní družstvo',
    '2275': 'Podnikatelská družstevní záložna',
    '2600': 'Citibank Europe plc, organizační složka',
    '2700': 'UniCredit Bank Czech Republic and Slovakia, a.s.',
    '3030': 'Air Bank a.s.',
    '3050': 'BNP Paribas Personal Finance SA, odštěpný závod',
    '3060': 'PKO BP S.A., Czech Branch',
    '3500': 'ING Bank N.V.',
    '4000': 'Expobank CZ a.s.',
    '4300': 'Národní rozvojová banka, a.s.',
    '5500': 'Raiffeisenbank a.s.',
    '5800': 'J & T BANKA, a.s.',
    '6000': 'PPF banka a.s.',
    '6100': 'Raiffeisenbank a.s.',
    '6200': 'COMMERZBANK Aktiengesellschaft, pobočka Praha',
    '6210': 'mBank S.A., organizační složka',
    '6300': 'BNP Paribas Fortis SA/NV, pobočka Česká republika',
    '6700': 'Všeobecná úverová banka a.s., pobočka Praha',
    '7910': 'Deutsche Bank Aktiengesellschaft Filiale Prag, organizační složka',
    '7950': 'Raiffeisen stavební spořitelna a.s.',
    '7960': 'ČSOB Stavební spořitelna, a.s.',
    '7970': 'MONETA Stavební Spořitelna, a.s.',
    '7990': 'Modrá pyramida stavební spořitelna, a.s.',
    '8030': 'Volksbank Raiffeisenbank Nordoberpfalz eG pobočka Cheb',
    '8040': 'Oberbank AG pobočka Česká republika',
    '8060': 'Stavební spořitelna České spořitelny, a.s.',
    '8090': 'Česká exportní banka, a.s.',
    '8150': 'HSBC Continental Europe, Czech Republic',
    '8190': 'Sparkasse Oberlausitz-Niederschlesien',
    '8198': 'FAS finance company s.r.o.',
    '8199': 'MoneyPolo Europe s.r.o.',
    '8200': 'PRIVAT BANK AG der Raiffeisenlandesbank Oberösterreich Aktiengesellschaft, pobočka Česká republika',
    '8220': 'Payment Execution s.r.o.',
    '8230': 'ABAPAY s.r.o.',
    '8250': 'Bank of China (Hungary) Close Ltd. Prague branch, odštěpný závod',
    '8255': 'Bank of Communications Co., Ltd., Prague Branch odštěpný závod',
    '8265': 'Industrial and Commercial Bank of China Limited Prague Branch, odštěpný závod',
    '8270': 'Fairplay Pay s.r.o',
    '8280': 'B - Efekt a.s.',
    '8293': 'Mercurius partners s.r.o.',
    '8299': 'BESTPAY s.r.o.',
    '8500': 'Ferratum Bank plc',
};

/**
 * It takes a prefix and an array of weights, and returns true if the sum of the string's characters
 * multiplied by the weights numbers is divisible by 11
 * @param {string} prefix - the first part of the number, before the hyphen
 * @param {number[]} weights - [2, 3, 4, 5, 6, 7]
 * @returns A function that takes two parameters, prefix and weights.
 */
export const moduloChecker = (prefix: string, weights: number[]) => {
    let sum = 0;
    Array.from(prefix, Number).forEach((weight, index) => (sum += (weights[index] ?? 0) * weight));
    if (sum % 11 != 0) {
        return false;
    }
    return true;
};

/**
 * `regexChecker` takes a test text as a string and a strict mode and returns if test pass or not
 * @param {string} testToString - The string to test against the regex.
 * @param {boolean} strictMode - boolean - If true, the regex will require the dash (-) to be present.
 * @returns A function that takes two parameters, testToString and strictMode.
 */
const regexChecker = (testToString: string, strictMode: boolean) => {
    const regexNonStrict = /^(([0-9]{0,6})-)?([0-9]{2,10})\/([0-9]{4})$/;
    const regexStrict = /^(([0-9]{0,6})-)([0-9]{2,10})\/([0-9]{4})$/;
    const regex = strictMode ? regexStrict : regexNonStrict;
    return regex.test(testToString);
};

/**
 * It takes all banks and return, if the bank code is in the list
 * @param banks - {[key: string]: string}
 * @param {string} code - The bank code to check
 */
export const bankCodeChecker = (banks: {[key: string]: string}, code: string | undefined) => banks[code ?? ''];

/**
 * It takes a bank account number, checks if it's valid, and returns the bank name if it is and if is bank account valid or not
 * @param  - `accountNumber` - The account number to be checked.
 * @returns A function that takes an object with the following properties:
 *     accountNumber: string;
 *     strict?: boolean;
 *     returnBankName?: boolean;
 */
export const checkBankAccountNumber = ({
    accountNumber,
    strict = true,
    returnBankName = false,
}: {
    accountNumber: string;
    strict?: boolean;
    returnBankName?: boolean;
}) => {
    // Kontrola formátu.
    const baseCheck = regexChecker(accountNumber, strict);
    if (baseCheck) {
        const [ucet, code] = accountNumber.split('/');

        const [prefix, base] = ucet?.split('-') ?? '';

        // Kontrola prefixu
        const validPrefix = moduloChecker((prefix ?? '')?.padStart(6, '0'), prefix_weights);
        if (!validPrefix) return false;

        // Kontrola samotneho cisla uctu bez prefixu
        const validBase = moduloChecker((base ?? '')?.padStart(10, '0'), base_weights);
        if (!validBase) return false;

        // Kontrola bankovního čísla
        const bankName = bankCodeChecker(banks, code);

        if (!bankName) return false;

        return returnBankName ? bankName : true;
    }
    return false;
};

/**
 * The function computes a number by either multiplying or dividing it with another number.
 * @param {string | number} input - The `input` parameter can be either a string or a number. It
 * represents the number that you want to perform calculations on.
 * @param {number} what - The `what` parameter represents the number by which the input will be
 * multiplied or divided.
 * @param {'multiplication' | 'divide'} smer - The parameter "smer" is a string that can have two
 * possible values: "multiplication" or "divide". It determines whether the function should perform
 * multiplication or division.
 * @returns The function `computeNumbers` returns either the result of multiplication or division based
 * on the value of the `smer` parameter.
 */
export const computeNumbers = (input: string | number | undefined | null, what: number, smer: 'multiplication' | 'divide') => {
    if (isNullOrUndefinedOrEmpty(input)) return '';
    const deleno = (Number(input) / what).toFixed(5).replace(/0+$/, '');
    const nasobeno = (Number(input) * what).toFixed(5);
    return smer === 'multiplication' ? nasobeno : deleno;
};

export const floatForcePrecision = (n: number, precision: number) => n?.toFixed(precision);

export const parseDecimal2 = (value: string | number | undefined | null, precision?: number) => {
    if (typeof value === 'number' && !precision) {
        return value;
    }
    if (typeof value === 'number' && precision) {
        return Number(floatForcePrecision(value, precision));
    }
    if (typeof value === 'string') {
        const parsedValue = value.includes(',') ? value.replace(',', '.') : value;
        if (precision) {
            return Number(floatForcePrecision(parseFloat(parsedValue), precision));
        }
        return Number(parseFloat(parsedValue));
    }
    return 0;
};

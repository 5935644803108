import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {Store} from '../../lib/StoreType';

export type SelectedItem = {[key: string]: boolean} | undefined;

export type ObsluhaProduktuStore = {
    readonly selectedItem: SelectedItem;
};

const initialState: ObsluhaProduktuStore = {
    selectedItem: undefined,
};

export const obsluhaProduktuSlice = createSlice({
    name: 'ObsluhaProduktu',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSelectedItem: (state: ObsluhaProduktuStore, {payload}: PayloadAction<SelectedItem>): ObsluhaProduktuStore => ({
            ...state,
            selectedItem: {...state.selectedItem, ...payload},
        }),
        deleteSelectedItem: (): ObsluhaProduktuStore => ({
            selectedItem: initialState.selectedItem,
        }),
    },
});

export const {setSelectedItem, deleteSelectedItem} = obsluhaProduktuSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const common = (state: Store) => state.common;

export default obsluhaProduktuSlice.reducer;

import {Close as CloseIcon} from '@mui/icons-material';
import {IconButton, Snackbar, SnackbarContent} from '@mui/material';
import {NotificationModel, NotificationType} from '../../../../models/notification/NotificationModel';
import {NotificationsMessageComponent} from './NotificationsMessageComponent';

type Props = {
    readonly notifications: ReadonlyArray<NotificationModel>;
    readonly onClose: () => void;
};

const styles = {
    info: {
        backgroundColor: 'background.paper',
        color: 'text.primary',
    },
    warning: {
        backgroundColor: 'custom.orange.main',
        color: 'text.primary',
    },
    error: {
        backgroundColor: 'error.main',
        color: 'error.contrastText',
        '&:hover': {
            color: 'background.paper',
        },
    },
    message: {
        width: '100%',
    },
    action: {
        position: 'absolute',
        top: 7,
        right: 12,
        paddingLeft: 0,
    },
};

const computeAutoHideTimeout = (notification: NotificationModel | undefined): number | undefined => {
    if (notification?.type === NotificationType.ERROR) {
        // Never autohide errors
        return undefined;
    }

    if (notification?.autohideTime) {
        return notification?.autohideTime < 0 ? undefined : notification?.autohideTime;
    }

    // Hide after fixed amount plus time dependent on message length
    return 2000 + (typeof notification?.text === 'string' ? notification?.text.length * 50 : 8000);
};

export const NotificationsComponent = ({onClose, notifications}: Props) => {
    if (notifications.length === 0) {
        // Render nothing
        return <span />;
    }

    const notification = notifications[0];

    const className =
        notification?.type === NotificationType.ERROR ? styles.error : notification?.type === NotificationType.WARNING ? styles.warning : styles.info;

    return (
        <Snackbar
            key={notification?.serial}
            anchorOrigin={{horizontal: 'center', vertical: 'top'}}
            open={notifications.length > 0}
            autoHideDuration={computeAutoHideTimeout(notification)}
            onClose={onClose}
        >
            <SnackbarContent
                sx={{
                    ...className,
                    '& .MuiSnackbarContent-message': {
                        width: '80%',
                    },
                    width: 500,
                }}
                message={<NotificationsMessageComponent notification={notifications[0]} moreMessages={notifications.length - 1} />}
                action={
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        sx={{
                            width: 40,
                            height: 40,
                            padding: 1,
                            ...className,
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            />
        </Snackbar>
    );
};

import {Context, Maybe, ProduktStav} from '@eon.cz/gemini11-graphql';
import {ChevronLeftOutlined, ChevronRightOutlined, PowerSettingsNew as LogoutIcon} from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Divider, Drawer, Icon, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Theme, Tooltip} from '@mui/material';
import {useRouter} from 'next/router';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {Store} from '../../../lib/StoreType';
import {apolloClient} from '../../../lib/apolloClient';
import {useCommonAction} from '../../action/CommonAction';
import {UserIcon} from '../../icons/UserIcon';
import {getMenuItems} from '../../service/CommonService';
import {DrawerDesktop} from '../../styledComponents/DrawerDesktop';
import {DrawerHeader} from '../../styledComponents/DrawerHeader';
import {selectedPath, useMatches} from '../../utils/CommonUtils';
type Props = {
    readonly open: boolean;
    readonly canShow: Maybe<boolean> | undefined;
    readonly theme: Theme;
    readonly onClose: () => void;
    readonly me: Context | undefined;
    readonly handleOnOpenRightProfileDrawer: () => void;
};

/**
 * App menu component
 */
export const AppLeftMenu = ({open, onClose, theme, canShow, me, handleOnOpenRightProfileDrawer}: Props) => {
    // methods
    const {push, pathname} = useRouter();
    const {logout, backToD24} = useCommonAction();
    const handleOnClickLogout = () => logout(apolloClient);
    const matches = useMatches();
    // redux state
    const {selfcareLogin} = useSelector((state: Store) => state?.common);
    // local state
    const [selectedItem, setSelectedItem] = useState<number | undefined>(undefined);

    // local variables
    const model = useMemo(() => getMenuItems(me?.ucet, me?.skupina, canShow), [me, canShow]);
    const disabledEnergetik = (!me?.produkt.aktivniOm && me?.produkt.produktStav === ProduktStav.ZMOCNENEC) ?? false;

    useEffect(() => {
        if (pathname) {
            setSelectedItem(selectedPath(pathname, canShow));
        }
    }, [pathname, canShow]);

    const handleOnClick = (pathname: string | undefined, index: number) => () => {
        setSelectedItem(index);
        push({pathname});
    };

    const handleDrawerClose = () => {
        onClose();
    };

    const handleBackToSelfcare = () => backToD24(apolloClient);

    const Draw = matches ? Drawer : DrawerDesktop;

    return (
        <Draw variant={matches ? undefined : 'permanent'} sx={{zIndex: matches ? 1500 : 0}} open={open} onClose={onClose}>
            {!matches && (
                <>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronRightOutlined /> : <ChevronLeftOutlined />}</IconButton>
                    </DrawerHeader>
                    <Divider sx={{height: 32}} />
                </>
            )}

            <List
                sx={{
                    width: 290,
                }}
            >
                {matches && (
                    <>
                        <ListItem onClick={handleOnOpenRightProfileDrawer}>
                            <ListItemIcon>
                                <UserIcon />
                            </ListItemIcon>
                            {me?.ucet?.jmeno ? `${me?.ucet.jmeno} ${me?.ucet.prijmeni}` : <FormattedMessage id="user.undefined" />}
                        </ListItem>
                        {selfcareLogin && (
                            <ListItem onClick={handleBackToSelfcare}>
                                <ListItemIcon>
                                    <ArrowBackIcon />
                                </ListItemIcon>
                                <FormattedMessage id="selfcare" />
                            </ListItem>
                        )}
                        <Divider />
                    </>
                )}
                {model.map(({label, description, route, icon, children}, index) => (
                    <Fragment key={label}>
                        {route && !children && (
                            <Tooltip title={<FormattedMessage id={description || label} />}>
                                <ListItemButton
                                    disabled={disabledEnergetik}
                                    selected={selectedItem === index}
                                    onClick={!children ? handleOnClick(route, index) : undefined}
                                    sx={{
                                        ...(selectedItem === index && {
                                            borderLeftStyle: 'solid',
                                            borderWidth: 3,
                                            borderColor: 'primary.main',
                                        }),
                                    }}
                                >
                                    <ListItemIcon>
                                        <Icon
                                            sx={{
                                                ...(selectedItem === index ? {color: 'primary.main', marginLeft: 0} : {marginLeft: 0.5}),
                                            }}
                                        >
                                            {icon}
                                        </Icon>
                                    </ListItemIcon>
                                    <FormattedMessage id={label}>
                                        {(msg) => (
                                            <ListItemText
                                                sx={{
                                                    ...(selectedItem === index && {color: 'primary.main'}),
                                                }}
                                                primary={msg}
                                            />
                                        )}
                                    </FormattedMessage>
                                </ListItemButton>
                            </Tooltip>
                        )}
                        {!route && children && (
                            <>
                                <Divider />
                                <List
                                    subheader={
                                        <ListSubheader component="div" id="nested-list-subheader">
                                            <FormattedMessage id={label}>
                                                {(msg) => (
                                                    <ListItemText
                                                        sx={{
                                                            lineHeight: '24px',
                                                            fontSize: '0.75rem',
                                                            textTransform: 'uppercase',
                                                        }}
                                                        primary={msg}
                                                    />
                                                )}
                                            </FormattedMessage>
                                        </ListSubheader>
                                    }
                                >
                                    {children.map((child) => (
                                        <ListItem key={child.label} button onClick={!child.children ? handleOnClick(child?.route, index) : undefined}>
                                            <ListItemIcon>
                                                <Icon>{child.icon}</Icon>
                                            </ListItemIcon>
                                            <FormattedMessage id={child.label}>{(msg) => <ListItemText primary={msg} />}</FormattedMessage>
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )}
                        {!route && !children && (
                            <ListItem>
                                <ListItemIcon>
                                    <Icon sx={{color: 'error.main'}}>{icon}</Icon>
                                </ListItemIcon>
                                <FormattedMessage id={label}>
                                    {(msg) => (
                                        <ListItemText primary={msg} secondary={<FormattedMessage id="menu.neni.implementovano" />} sx={{color: 'error.main'}} />
                                    )}
                                </FormattedMessage>
                            </ListItem>
                        )}
                    </Fragment>
                ))}
            </List>
            <List
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    marginLeft: 1,
                }}
            >
                <Divider />
                <ListItem button onClick={handleOnClickLogout}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <FormattedMessage id="menu.odhlasit.se">{(msg) => <ListItemText primary={msg} />}</FormattedMessage>
                </ListItem>
            </List>
        </Draw>
    );
};

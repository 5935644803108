import {Grid, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Div} from '../../common/styledComponents/Div';
import {sanitizeHTML, useMatches} from '../../common/utils/CommonUtils';

declare const window: any;

type Props = {
    /**
     * HTML with reasons why is login disabled
     */
    readonly loginDisabledHtml?: string;
};

/**
 * Component for displaying reasons why is login disabled
 */
export const LoginDisabled = ({loginDisabledHtml}: Props) => {
    const [backgroundImage, setBackgroundImage] = useState<string>("url('/static/images/EGD_admin_login_disabled_landscape.svg')");
    const [height, setHeight] = useState<number>(1000);
    const matches = useMatches();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setBackgroundImage(
                window?.innerHeight > 2100 || matches
                    ? `url('/static/images/EGD_admin_login_disabled_portrait.svg')`
                    : `url('/static/images/EGD_admin_login_disabled.svg')`,
            );
            setHeight(window.innerHeight);
        }
    }, [matches]);

    return (
        <Div
            sx={{
                backgroundImage,
                backgroundSize: '100% 100%',
                backgroundColor: 'background.default',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100%',
                display: 'flex',
            }}
        >
            <Grid
                container
                sx={{
                    width: height > 2100 ? '30vh' : '60vh',
                    margin: 'auto',
                    marginLeft: matches ? undefined : 50,
                }}
            >
                <Grid item xs={12}>
                    <Typography
                        variant={matches ? 'h3' : 'h1'}
                        component="div"
                        sx={{
                            color: 'primary.main',
                            textAlign: 'center',
                            marginBottom: 5,
                            width: matches ? 250 : 600,
                            marginLeft: matches ? 15 : 30,
                        }}
                    >
                        <FormattedMessage id="portal.mimo.provoz" />
                    </Typography>
                </Grid>
                {loginDisabledHtml && (
                    <Grid item xs={12}>
                        <Typography
                            variant="h5"
                            sx={{
                                color: 'common.white',
                                textAlign: 'center',
                                marginBottom: 5,
                            }}
                            style={{textAlign: 'center'}}
                            dangerouslySetInnerHTML={{__html: sanitizeHTML(loginDisabledHtml)}}
                        />
                    </Grid>
                )}
            </Grid>
        </Div>
    );
};

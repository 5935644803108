import {MoreVertOutlined} from '@mui/icons-material';
import {Divider, Icon, IconButton, Menu, Paper, PaperProps, Typography} from '@mui/material';
import {FC, MouseEvent, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Div} from '../../styledComponents/Div';
import {useMatches} from '../../utils/CommonUtils';
import {LongCellContent} from '../grid/LongCellContent';

export type PaperBlockProps = PaperProps & {
    readonly titleBlock: string | JSX.Element | undefined;
    readonly description?: string | JSX.Element;
    readonly titleBlockIcon?: JSX.Element | string;
    readonly titleRightBlock?: JSX.Element | string;
    readonly centerTitle?: boolean;
    readonly paper?: boolean;
};

export const PaperBlock: FC<PaperBlockProps> = ({children, titleBlock, titleBlockIcon, description, centerTitle, titleRightBlock, paper = true}) => {
    const matches = useMatches('800px');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const Component = paper ? Paper : Div;

    const handleMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Component sx={{backgroundColor: 'background.paper', position: 'relative'}}>
            <Div
                sx={{
                    width: '100%',
                    position: 'absolute',
                    height: 4,
                    top: 0,
                    left: 0,
                    borderTopLeftRadius: 2,
                    borderTopRightRadius: 2,
                    backgroundColor: 'primary.main',
                }}
            />

            <Div
                sx={{
                    ...(centerTitle
                        ? {
                              display: 'contents',
                              textAlign: 'center',
                          }
                        : {
                              display: 'flex',
                              alignItems: 'center',
                              height: 60,
                          }),
                }}
            >
                {titleBlockIcon && (
                    <Icon
                        sx={{
                            marginLeft: 2,
                            display: 'flex',
                        }}
                    >
                        {titleBlockIcon}
                    </Icon>
                )}

                {titleBlock && (
                    <Typography
                        sx={{
                            marginLeft: 2,
                        }}
                        variant={matches ? 'h6' : 'h5'}
                    >
                        {typeof titleBlock === 'string' ? (
                            <LongCellContent maxWidth={matches ? 'max-content' : '500px'} content={<FormattedMessage id={titleBlock} />} />
                        ) : (
                            titleBlock
                        )}
                    </Typography>
                )}
                {titleRightBlock && (
                    <Div
                        sx={{
                            marginLeft: 'auto',
                        }}
                    >
                        {matches ? (
                            <>
                                <IconButton
                                    size="small"
                                    aria-label="action-menu"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <MoreVertOutlined />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {titleRightBlock}
                                </Menu>
                            </>
                        ) : (
                            titleRightBlock
                        )}
                    </Div>
                )}

                {description && (
                    <Typography variant="subtitle1">{typeof description === 'string' ? <FormattedMessage id={description} /> : description}</Typography>
                )}
            </Div>
            <Divider />
            <Div sx={(theme) => ({margin: theme.spacing(2, 2, 2, 2), paddingBottom: 2})}>{children}</Div>
        </Component>
    );
};

import {ReactNode} from 'react';

export type ValidationError = {
    code?: string;
    field?: string;
    index?: number;
    message: string | ReactNode;
    name?: string;
    relatedIndex?: number;
    type?: ValidationErrorType;
};

export const ValidationErrorCode = {} as const;

export type ValidationErrorCode = (typeof ValidationErrorCode)[keyof typeof ValidationErrorCode];

export enum ValidationErrorType {
    CRITICAL,
    WARNING,
    CUSTOM,
}

export type GridOrderColumns = any;

/**
 * Order by definition
 */
export type GridOrderBy = {
    readonly column: GridOrderColumns;
    readonly asc: boolean;
};
